<template>
  <div>
    <div v-if="displayVehicle && switchable" :class="mainClass">
      <div class="car-info-container">
        <div class="car-info">
          <h2 class="title">Votre voiture</h2>
          <div><strong>{{vehicleName}}</strong></div>
          <div v-if="!$isMobile()">{{displayedVehicle.designation}}</div>
          <div v-if="!$isMobile()">{{plateNumber}}</div>
        </div>
        <div class="actions-button">
          <dpa-btn v-if="hideSearch" text @click="showVehicleInfo">Plus d'info</dpa-btn>
          <dpa-btn :color="!hideSearch ? 'white' : 'primary'" @click="modifyVehicle">Modifier</dpa-btn>
          <dpa-btn v-if="!hideSearch" color="primary" @click="searchProduct">Rechercher une pièce</dpa-btn>
        </div>
      </div>
    </div>
    <div v-else-if="displayVehicleInfo" class="block vehicle-wrapper">
    <span class="close-icon tiny absolute right" @click="displayVehicleInfo = false; displayVehicle = true" />
      <v-flex flex-column>
        <div><strong>{{vehicleName}}</strong></div>
        <div>{{displayedVehicle.designation}}</div>
        <div class="fields-wrapper">

          <Field v-if="plateNumber" label="Plaque d'immat" :value="plateNumber" />
          <Field v-if="firstRegistrationDate" label="Mise en circulation" :value="firstRegistrationDate" />
          <Field v-if="displayedVehicle.powerSupply"  label="Alimentation" :value="displayedVehicle.powerSupply" />
          <Field v-if="enginePower" label="Puissance" :value="enginePower" />
          <Field v-if="this.displayedVehicle.carBody" label="Carrosserie" :value="this.displayedVehicle.carBody" />
          <Field v-if="this.displayedVehicle.engine" label="Code moteur" :value="this.displayedVehicle.engine" />
        </div>
      </v-flex>
    </div>
    <div v-else :class="carSelectorClass">
    <span v-if="switchable && !vehicleEmpty"
          class="close-icon tiny absolute right dark"
          @click="displayVehicle = true" />
      <div class="car-selector-form-container">
        <div class="car-selector-header">
          <h2 class="title">Sélectionnez votre véhicule</h2>
          <div class="link" v-for="mode in filteredSelectionMode"
               v-bind:key="`car-selection-mode-${mode.id}`"
               @click="selectMode(mode.id)">
            {{mode.text}}
          </div>
        </div>

        <div v-if="selectedSelectionModeId === 0">
          <SavedVehicleSelection @on-result="handleVehicleSelection" />
        </div>
        <div v-if="selectedSelectionModeId === 1">
          <PlatenumberSelection @on-result="handleVehicleSelection" />
        </div>
        <div v-else-if="selectedSelectionModeId === 2">
          <MineTypeSelection @on-result="handleVehicleSelection" />
        </div>
        <div v-else-if="selectedSelectionModeId === 3">
          <CarModelSelection @on-result="handleVehicleSelection" />
        </div>
      </div>
    </div>

    <Dialog title="Nous avons trouvé votre voiture !" :dialog="displayCarFound" @agreed="confirmVehicleSelection">
      <template v-if="vehicle" v-slot:content>
        <h3>{{vehicleName}}</h3><span>{{vehicle.designation}}</span>
        <div>Code moteur : {{ vehicle.engine }}</div>
        <div>Alimentation : {{ vehicle.powerSupply }}</div>
        <div v-if="plateNumber">Immatriculation : {{ plateNumber }}</div>
        <div v-if="firstRegistrationDate">Mise en circulation : {{ firstRegistrationDate }}</div>
        <div>Production : {{`du ${$formatDate(vehicle.productionStart, 'D/MM/YYYY')} au ${$formatDate(vehicle.productionEnd, 'D/MM/YYYY')}`}}</div>

        <div v-if="isAuthenticated">
          <v-checkbox v-model="saveAsNewVehicle" label="Sauvegarder dans ma liste de véhicule"></v-checkbox>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <dpa-btn text @click="confirmVehicleSelection">Ok</dpa-btn>
        <dpa-btn v-if="!hideSearch" color="primary" @click="confirmVehicleSelection(vehicle)">Rechercher une pièce</dpa-btn>
      </template>
    </Dialog>
    <Dialog title="Nous n'avons pas trouvé de véhicule pour cette plaque d'immatriculation !"
            :dialog="displayCarNotFound"
            @agreed="confirmVehicleSelection">
      <template v-slot:content>
        <strong >Pas de panique vous pouvez toujours faire une recherche par modèle de véhicule ou par type mine</strong>
        <v-flex flex-column align-center style="margin-top: 1em">
          <router-link v-if="!hideSearch && !compatibilityMode" to="/catalogue?type=vehicle">Rechercher par modèle de véhicule</router-link>
          <router-link v-if="!hideSearch && !compatibilityMode" to="/catalogue?type=mine">Rechercher par type MINE</router-link>
        </v-flex>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <dpa-btn text @click="confirmVehicleSelection">Ok</dpa-btn>
      </template>
    </Dialog>
  </div>


</template>

<script>

  import PlatenumberSelection from "@/components/vehicle/PlatenumberSelection";
  import MineTypeSelection from "@/components/vehicle/MineTypeSelection";
  import CarModelSelection from "@/components/vehicle/CarModelSelection";
  import SavedVehicleSelection from "@/components/vehicle/SavedVehicleSelection";
  import Field from "@/components/vehicle/SimpleField.vue";
  import DpaBtn from "@/components/Button";
  import Dialog from "@/components/SimpleDialog.vue";
  import {isEmpty} from "lodash-es"
  export default {
    components: {MineTypeSelection, PlatenumberSelection, CarModelSelection, SavedVehicleSelection, Field, DpaBtn, Dialog},
    props: {
      vehicle: Object,
      switchable: Boolean,
      hideSearch: Boolean,
      dark: Boolean,
      dense: Boolean,
      compatibilityMode: Boolean,
      background: String
    },
    watch: {
      vehicle(val) {
        this.displayedVehicle = val ? val : {}
        this.displayVehicle = val != null && val !== {}
      },
      isAuthenticated(val) {
        this.selectedSelectionModeId = val && this.$store.state.session.vehicles.length > 0 ? 0 : 1
      }
    },
    data() {
      return {
        saveAsNewVehicle: true,
        displayCarFound: false,
        displayCarNotFound: false,
        displayedVehicle: this.vehicle ? this.vehicle : {},
        displayVehicle: !isEmpty(this.vehicle),
        displayVehicleInfo: false,
        selectedSelectionModeId: 1,
        selectionMode: [{
          id: 0,
          text: "dans mes véhicules"
        },
          {
          id: 1,
          text: "par immatriculation",
        },{
          id: 2,
          text: "par carte grise",
        },{
          id: 3,
          text: "par modèle de véhicule",
        }]
      }
    },
    computed: {
      carSelectorClass() {

        let clazz = "car-selector"
        if (this.background) clazz += ` ${this.background}`
        return clazz
      },
      isAuthenticated() {
        return this.$keycloak && this.$keycloak.authenticated
      },
      mainClass() {
        let clazz = "block vehicle-wrapper"
        if (this.dark) clazz += " dark"
        if (this.dense) clazz += " dense"
        return clazz
      },
      vehicleName() {
        return !isEmpty(this.displayedVehicle)  ? `${this.displayedVehicle.brandName} ${this.displayedVehicle.modelName}` : null
      },
      designation() {
        return !isEmpty(this.displayedVehicle) ? this.displayedVehicle.designation : null
      },
      enginePower() {
        return this.displayedVehicle && this.displayedVehicle.enginePowerCV && this.displayedVehicle. enginePowerKW
            ? `${this.displayedVehicle.enginePowerCV} ch (${this.displayedVehicle.enginePowerKW} kw)`
            : null
      },
      plateNumber() {
        return !isEmpty(this.displayedVehicle) ? this.displayedVehicle.platenumber : null
      },
      firstRegistrationDate() {
        return !isEmpty(this.displayedVehicle)
            ? this.$formatDate(this.displayedVehicle.preRegistrationDate, 'D/MM/YYYY')
            : null
      },
      filteredSelectionMode() {
        return this.selectionMode.filter(mode => {

          if (mode.id === 0) {
            return this.isAuthenticated && mode.id !== this.selectedSelectionModeId
          } else {
            return mode.id !== this.selectedSelectionModeId
          }
        })
      },

      vehicleEmpty() {
        return isEmpty(this.vehicle)
      }
    },
    methods: {
      selectMode(modeId) {
        this.selectedSelectionModeId = modeId
      },
      modifyVehicle() {
        this.displayVehicle = false
        this.displayVehicle = null
      },
      showVehicleInfo() {
        this.displayVehicle = false
        this.displayVehicleInfo = true
      },
      handleVehicleSelection(vehicle) {

        this.$emit("on-result", vehicle)

        if (vehicle) this.$store.commit("vehicle/setVehicle", vehicle)
        else {
          this.displayCarNotFound = true
          return
        }

        if (!this.compatibilityMode) {
            this.displayCarFound = !this.$store.getters["session/hasVehicle"](vehicle)
            this.displayedVehicle = vehicle
            this.displayVehicle = true

        }
      },
      searchProduct() {

        let to = "/catalogue"
        if (this.vehicle && this.vehicle.url) to += `/v${this.vehicle.url}`
        this.$router.push(to)
      },
      confirmVehicleSelection(vehicle) {

        if (this.displayCarFound) this.saveNewVehicle()

        if (!this.compatibilityMode && this.displayCarFound && vehicle) {

          let to =  `/catalogue`
          if (vehicle.url) to += `/v${vehicle.url}`
          this.$router.push(to)
        }

        this.displayCarFound = false
        this.displayCarNotFound = false

      },
      saveNewVehicle() {
        if (this.isAuthenticated && this.saveAsNewVehicle) {
          this.$store.dispatch("session/saveVehicle", this.vehicle)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/colors";

  .title {
    color: $primary-color;
    margin-right: 0.3em;
    margin-bottom: 4px;
  }

  .car-selector {
    background-color: $background-color-gray-dark;
    padding: 1em;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    position: relative;

    .car-selector-form-container {
      display: flex;
      padding: 1em;
      justify-content: space-around;
      width: 100%;
      margin: auto;
      align-items: center;

      .form-container {

        label {
          padding: 0 0 0.5em 0;
        }

        .field-set {
          display: flex;
          height: 100%;

          .v-input {
            margin-left: 0.5em;
          }
        }

        .input-mine {
          background: #FFFFFF;
          box-sizing: border-box;
          padding-left: 1em;
          border: 0;
          text-align: center;
          text-transform: uppercase;
          border-radius: .5rem;
          letter-spacing: 2px;
          font-size: larger;
          outline: none;
          height: 50px;
          min-width: 200px;
          margin-right: 1em;
        }

        .plate-button {
          display: flex;
          align-items: center;
        }
      }
    }

    .car-selector-footer {
      display: flex;
      justify-content: space-evenly;
    }
  }

  .car-selector.gray {
    background-color: $gray;
    color: $text-color
  }

  .car-info-container {
    display: flex;
    padding: 1em;
    justify-content: space-around;
    align-items: center;
    flex: 1;

    .car-info {
      display: flex;
      flex-direction: column;
    }

    .actions-button {
      display: flex;
    }

    .actions-button button:first-child {
      margin-right: 0.5em;
    }
  }

  .vehicle-wrapper.dense {
    .car-info-container {
      justify-content: space-between;
      padding: 0;
    }
  }

  .vehicle-wrapper {
    padding: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .fields-wrapper {
      display: flex;
      flex-wrap: wrap;

    }

    .actions-button div {
        margin-left: 0.5em;
    }
  }

  .vehicle-wrapper.dark {
    background-color: $background-color-gray-dark;
    border: none;
    color: #FFFFFF;

    .actions-button {
      color: black;
    }
  }


  @media only screen and (max-width: 1000px) {

    .car-selector {
      .car-selector-form-container {
        padding: 0 0.5em 0 0.5em;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .car-selector-header {
          text-align: center;
          margin-bottom: 2em;
        }
      }
    }

    .vehicle-wrapper {
      padding: 1em;
    }

    .car-info-container {
      padding: 0;
      flex-direction: column;


      .car-info {
        flex: 0.6;
        flex-direction: column;
        align-items: center;
      }
      .actions-button {
        flex: 0.4;
        margin-top: 1em;
        flex-direction: row;

        button {
          margin-right: 0.5em;
        }
      }
    }

  }

  @media only screen and (max-width: 374px) {


    .car-info-container {

      .actions-button {
        flex-direction: column;
        justify-content: center;

        button {
          margin-right: 0;
          margin-bottom: 0.5em;
          width: 100%;
        }
      }
    }

  }


</style>
