import axios from 'axios'

export default {

    async getNearestRelayPoints({address, weight, shippingDate}) {

        try {

            let params = ""
            if (address.zipcode) params += `?zipcode=${address.zipcode}`
            if (address.city) params += `&city=${encodeURIComponent(address.city)}`
            if (address.street) params += `&street=${encodeURIComponent(address.street)}`
            if (weight) params += `&weight=${weight}`
            if (shippingDate) params += `&shippingDate=${encodeURIComponent(shippingDate)}`

            let response = await axios.get(`/deliveries/relays${params}`, address)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },
    async getRelayById(relayId) {

        try {

            let response = await axios.get(`/deliveries/relays/${relayId}`)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },

    async calculateDeliveryTime(deliveryType, relayId) {

        try {

            let response = await axios.get(`/deliveries/${deliveryType}/relays/${relayId}`)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },
}
