import axios from 'axios'

export default {

    async refreshLogin() {
        return new Promise((success, die) => {
            axios.get("/users/refresh", {}).then(() => {
                success()
            }).catch(die)
        })
    },

    async getUserInfo() {

        return new Promise((success, die) => {
            axios.get("/users/me").then(response => {
                success(response ? response.data : null)
            }).catch(die)
        })
    },

    async saveBillingAddress(address) {

        try {
            let response = await axios.put("/users/address", address)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async saveVehicle(vehicle) {

        try {
            let response = await axios.put("/users/vehicle", vehicle)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async deleteVehicle(vehicleId) {

        try {
            let response = await axios.delete(`/users/vehicle/${vehicleId}`)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async saveUserInfo(user) {
        try {
            let response = await axios.put("/users/info", user)
            return response ? response.data : null
        } catch (e) {
            if (e.message.indexOf("409")) throw 409
            throw 500
        }

    },

    async answerSurvey(payload) {
        let response = await axios.post(`/surveys/${payload.surveyId}`, payload.response)
        return response.data
    }
}
