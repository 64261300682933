<template>
  <div :class="status" v-if="availabilityDate">
    <div style="display: flex; align-items: center; justify-content: flex-end">
      <img class="package-img" alt="Paquet" :src="packageImg"/>
      <span> Disponible</span>
    </div>
    <div class="availability">{{ displayAvailability }}</div>
  </div>
</template>

<script>

import dayjs from "dayjs";


export default {
  props: {
    availability: String,
    alignLeft: Boolean
  },
  data() {
    return {
      availabilityDate: this.getAvailabilityDate(this.availability),
      availabilityInHalfDays: this.getAvailabilityInHalfDays(this.availability),
    }
  },
  watch: {
    availability(val) {
      this.availabilityDate = this.getAvailabilityDate(val)
      this.availabilityInHalfDays = this.getAvailabilityInHalfDays(val)
    }
  },
  computed: {
    packageImg() {
      return this.availabilityInHalfDays <= 3
          ? require("@/assets/icons/package-variant-closed-green.svg")
          : require("@/assets/icons/package-variant-closed.svg")
    },
    displayAvailability() {
      return this.availabilityDate
          ? this.$computeAvailabilityDisplay(this.availabilityDate)
          : null
    },
    status() {

      let clazz = "availability-wrapper"
      switch (this.availabilityInHalfDays) {
        case 0:
          clazz += " available"
          break;
        case 1:
          clazz += " available"
          break;
        case 2:
          clazz += " tomorrow"
          break;
        case 3:
          clazz += " tomorrow"
          break;
        default:
          clazz += " not-available"
          break;

      }

      if (this.alignLeft) clazz += " left"

      return clazz
    },
  },
  methods: {
    getAvailabilityDate(date) {
      return date ? dayjs(date) : null
    },
    getAvailabilityInHalfDays(availabilityDate) {

      if (!availabilityDate) return

      let now = dayjs()
      let duration = dayjs.duration(dayjs(availabilityDate).diff(now));
      let hours = duration.asHours();
      return Math.trunc(hours / 12)
    },
  }
}
</script>

<style lang="scss" scoped>

@import "src/styles/colors";

.package-img {
  height: 1.9em;
  width: auto;
  margin-right: 0.1em;
  color: #43a047;
}
.availability-wrapper {
  font-weight: bold;
  margin-top: 0.5em;
}

.availability-wrapper.left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;


}
.availability-wrapper.available {
  color: $green;
}

.availability-wrapper.tomorrow {
  color: $green
}

.availability-wrapper.not-available {
  color: $text-color
}

@media only screen and (max-width: 1000px) {

}
</style>
