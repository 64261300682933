<template>
  <div class="page content in-column">

    <div>
      Votre magasin
    </div>
    <div v-if="$isMobile()" class="header">
      <img class="logo" src="/logo_white.png" alt="logo Drive Pièces Auto"/><div class="location">Pringy</div>
    </div>
    <div v-else class="header">
      <img class="logo" src="/logo_white.svg" alt="logo Drive Pièces Auto"/><div class="location">Pringy</div>
    </div>
    <div class="text">
      <p>est actuellement en maintenance.</p>
      <p>Nous faisons le maximum pour rétablir notre service.</p>
      <p><a href="mailto:contact@drivepiecesauto.com" class="link">Nous contacter</a></p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content {
  background-color: #27282C;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;

  font-size: x-large;
  font-weight: 400;
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: -66px;
  padding: 0 3em !important;

  p {
    margin: 1em 0;
  }

  .text {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.header {
  display: flex;
  align-items: center;

  .logo {
    max-width: 600px;
  }
}

.location {
  font-size: xxx-large;
  padding: 0.2em 0 0 0.5em;
  color: #FFFFFF;
  font-family: 'Nothing You Could Do', cursive;
}


@media only screen and (max-width: 1000px) {
  .content {
    font-size: larger;
  }

  .header {
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {

  .header {
    .logo {
      max-width: calc(95%);
    }
  }
  .content {
    font-size: large;
    padding: 0 1em !important;
    margin-top: 0;
  }

  .location {
    font-size: xx-large;
  }
}



</style>
