<template>
  <div class="button-cart-container">
    <dpa-btn v-if="checkAvailability || item.availability" color="primary"
             :loading="loading" wide
             @click="addProductToCart(item)">
      <v-icon v-if="icon">mdi-cart</v-icon>
      <span v-else>{{buttonText}}</span>
    </dpa-btn>

    <div v-else>
<!-- FIXME TO DISCUSS     <span class="link" @click="onPhoneNumberClick()">Nous contacter</span>-->
      <dpa-btn color="primary" disabled wide
               @click="onPhoneNumberClick()">
        <v-icon v-if="icon">mdi-phone</v-icon>
        <span v-else>Non disponible</span>
      </dpa-btn>
    </div>

    <Dialog :dialog="showDialog" max-width="900" :full-width="$isMobile()">

      <template v-slot:title>
        <div v-if="!showDialogNotAvailable" class="title-success">
          <div>Merci !&nbsp;</div>
          <div>Votre produit à bien été ajouté au panier</div>
        </div>
        <div v-else class="title-error">
          <div>Malheureusement ce produit n'est plus disponible !</div>
        </div>
      </template>
      <template v-slot:content>
        <CartProductCartouche :value="cartProduct" flat no-delete summarize :align-right="$isMobile()" />
      </template>
      <template v-slot:actions> <!-- class="dialog-actions" -->
          <v-spacer></v-spacer>
          <dpa-btn text @click="showDialog = false" :wide="$isMobile()">Continuer mes achats</dpa-btn>
          <dpa-btn color="primary" @click="gotoCart" :wide="$isMobile()">Accéder au panier</dpa-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/SimpleDialog.vue";
import DpaBtn from "@/components/Button";
import CartProductCartouche from "@/pages/cart/components/CartProductCartouche";
import {mapState} from "vuex";

export default {
  components: {CartProductCartouche, DpaBtn, Dialog},
  props: {
    item: Object,
    icon: Boolean,
    disableCarIdentification: Boolean,
    checkAvailability: Boolean,
    text: String
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      showDialogNotAvailable: false,
      showPhoneNumber: false,
      quantity: 1,
      phoneNumber: "01 64 41 80 97",
      buttonText: this.text ? this.text : "Ajouter au panier"
    }
  },
  computed: {
    ...mapState({
      cartProduct(state) {
        return state.cart.items.filter(i => i.product.reference === this.item.reference)[0] ?? {}
      }
    }),
    notAvailableText() {
      return this.showPhoneNumber ? this.phoneNumber : "Appelez nous".toUpperCase()
    }
  },
  methods: {
    async addProductToCart(item) {

      try {
        this.loading = true
        await this.$store.dispatch("cart/addItem", {
          product: item,
          noCarId: this.disableCarIdentification
        })
        this.showDialog = true

      } catch (e) {
        this.showDialogNotAvailable = true
        this.showDialog = true

      } finally {
        this.loading = false
      }
    },
    imgHeight() {
      return this.$isMobile() ? 100 : 150
    },
    imgWidth() {
      return this.$isMobile() ? 100 : 150
    },
    gotoCart() {
      this.showDialog = false
      this.$emit("on-go-to-cart")
      this.$router.push('/panier')
    },
    onPhoneNumberClick() {
      if (!this.showPhoneNumber && !this.icon) {
        this.showPhoneNumber = true
      } else {
        window.open(`tel:${this.phoneNumber.trim()}`);
        this.showPhoneNumber = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import "src/styles/colors";


.title-error {
  color: $red;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.title-success {
  color: $green;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.title-success div:first-child {
  margin-right: 0.5em;
}


.product {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .product-description-wrapper {
    display: flex;
    width: 100%;

    .product-description {
      display: flex;
      flex-direction: row;
      padding-left: 1em;
      justify-content: space-between;
      width: 100%;

      .name {
        font-size: x-large;
      }

      .price {
        font-size: large;
        margin-bottom: 1em;
      }

      .price-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
        font-size: x-large;

        i {
          font-size: large;
          opacity: 0.8;
        }

      }
    }
  }
}

@media only screen and (max-width: 1000px) {



  .title-success {
    flex-direction: column;
  }

  .title-success div:first-child {
    margin-right: 0;
    margin-bottom: 0.5em;
  }

  .actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .product {
    .product-description-wrapper {

      .product-description {
        flex-direction: column;

        img {
          height: 50px;
          width: auto;
        }

        .price-block {
          flex-direction: row;
          margin-top: 1em;
          align-items: center;
        }
      }
    }
  }
}
</style>
