export function filterProducts (filter, leads) {
    let filteredList = [...leads]

    // Filter status
    if (filter.status !== 'all') {
        const filtered = filteredList.filter(lead => lead.status === filter.status)
        filteredList = filtered
    }

    // Search
    if (filter.search !== '') {
        const searchList = []
        const searchTerm = filter.search.toLowerCase()
        for (let i = 0; i < filteredList.length; i++) {
            if (
                (filteredList[i].brand !== null && filteredList[i].brand.toLowerCase().includes(searchTerm)) ||
                (filteredList[i].reference !== null && filteredList[i].reference.toLowerCase().includes(searchTerm)) ||
                    (filteredList[i].type !== null && filteredList[i].type.toLowerCase().includes(searchTerm))
            ) {
                searchList.push(filteredList[i])
            }
        }
        filteredList = searchList
    }

    return filteredList
}