<template>
    <v-dialog v-model="isOpen" :max-width="width" :content-class="dialogClass"
              transition="v-expand-x-transition" :scrollable="scrollable"
              :fullscreen="fullWidth" :persistent="persistent"
              @click:outside="$emit('agreed')">
        <v-card :max-width="width" class="dialog-card">
            <v-card-title v-if="!noCard " class="headline">
              <slot name="title">
               {{title}}
              </slot>
            </v-card-title>
            <slot v-else name="title">
              {{title}}
            </slot>

          <v-card-text v-if="!noCard" class="dialog-content">
            <slot name="content" />
          </v-card-text>
          <slot v-else name="content" />

          <v-card-actions v-if="!noCard" class="dialog-actions">
             <slot name="actions" />
          </v-card-actions>
          <slot v-else name="actions" />
          <slot/>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'SimpleDialog',
        props: {
          title: String,
          message: String,
          dialog: Boolean,
          maxWidth: String,
          fullWidth: Boolean,
          panel: Boolean,
          noCard: Boolean,
          persistent: Boolean,
          scrollable: Boolean
        },
      data() {
          return {
            width: this.maxWidth ? this.maxWidth : this.$isMobile() ? 400 : 780,
            isOpen: this.dialog
          }
      },
      watch: {
          dialog(value) {
            this.isOpen = value
          }
      },
      computed: {
        dialogClass() {
          let clazz = ""
          if (this.panel) clazz += " dialog-panel"
          return clazz
        }
      }
    }
</script>

<style scoped>
>>> .dialog-panel {
  position: absolute;
  right: 0;
  margin: 0;
  height: 100%;
  border-radius: 0;
}

>>> .v-dialog:not(.v-dialog--fullscreen).dialog-panel {
  max-height: 100%;
}

>>> .dialog-panel .dialog-card {
  height: 100%;
}

.dialog-panel > .v-card.dialog-card > .v-card__title {
  padding: 0;
}
.dialog-card.v-sheet.v-card {
  border-radius: 0 !important;
}
</style>

<style lang="scss" scoped>

.dialog-content {
  font-size: large;
}
.headline {
  margin-bottom: 1em;
}

@media only screen and (max-width: 1000px) {
  .dialog-actions {
    display: flex;
    flex-direction: column;
  }
}
</style>
