<template>
  <v-form class="form-container">
    <v-flex class="field-set" flex-column>
      <label>Entrez le type mine de votre véhicule</label>
      <v-flex>
        <v-autocomplete
            label=""
            v-model="selectedMineType"
            :items="filteredMineTypes"
            item-text="name"
            item-value="id"
            background-color="white"
            color="black"
            clearable
            :search-input.sync="search"
            class="input-mine"
            placeholder="DE14K2"
            @change="selectVehicle"
            :loading="loading"
            no-data-text="Aucune données"
            solo />

      </v-flex>
    </v-flex>
  </v-form>
</template>

<script>

import {debounce} from "lodash-es"

export default {
  components: {},
  data() {
    return {
      search: null,
      selectedMineType: null,
      mineTypes: [],
      filteredMineTypes: [],
      loading: false
    }
  },
  watch: {
    search(val) {

      if (val) {
        if (val.length < 5) {
          this.$store.commit("vehicle/resetMineTypes")
          this.mineTypes = []
          this.filteredMineTypes = []

          // type min between 12 and 15 characters
        } else if (val.length >= 5 && val.length <= 15 && !this.loading) {
          this.searchMineTypes(val)
        } else {
          this.filter(val)
        }
      }
    }
  },
  methods: {
    searchMineTypes: debounce(async function(term) {

      try {
        this.loading = true
        this.mineTypes = await this.$store.dispatch("vehicle/searchMineTypes", {term: term})
        this.filter(term)
      } catch (e) {
       this.error = "Erreur"
      } finally {

        this.loading = false
      }

    }, 500),
    filter(val) {
      this.filteredMineTypes = this.mineTypes.filter(b => (b || '').toLowerCase().indexOf((val || '').toLowerCase()) > -1)

    },
    async selectVehicle(mineType) {
      let vehicle = await this.$store.dispatch("vehicle/checkVehicle", {
        id: mineType, type: 'MINE_TYPE'
      })
      this.$emit("on-result", vehicle)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/colors";

  .form-container {

    label {
      padding: 0 0 0.5em 0;
    }

    .field-set {
      display: flex;
      height: 100%;

      .v-input {
        margin-left: 0.5em;
      }
    }



    .plate-button {
      display: flex;
      align-items: center;
    }
  }

</style>
