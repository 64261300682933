import dayjs from "dayjs";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const duration = require('dayjs/plugin/duration')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
require('dayjs/locale/fr')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.locale('fr')
dayjs.tz.setDefault("Europe/Paris")

