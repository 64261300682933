import {AddressApi, ReturnsApi, UserApi, ShopApi} from "../../api";
import {find, findIndex, cloneDeep, isEmpty} from "lodash-es"
import VouchersApi from "@/api/VouchersApi";

const state = () => ({
    user: null,
    billingAddress: null,
    vehicles: [],
    returns: [],
    vouchers: [],
    loading: false,
    error: null,
    returnStatuses: {
        CREATED: "En cours",
        IN_PROGRESS: "En cours",
        TERMINATED: "Terminée"
    },
    returnItemStatuses: {
        CREATED: "En cours",
        VALIDATED: "Validée",
        CHECKING: "En cours",
        DECLINED: "Rejetée"
    },
    returnReasons: {
        NOT_SUITABLE: "Pas adapatée au véhicule",
        BROKEN: "Pièce endommagée ou incomplête",
        RETURN_DEPOSIT: "Retour consigne",
        WARRANTY: "Demande de garantie"
    },
    sessionExpired: false,
    campaign: null,
    campaignTrackingId: null,
    campaignTrackingIdType: null,
    campaignTrackingIdExpiryIn: null,
    surveyAnswered: false
})

const getters = {

    getUser: (state) => {
        return state.user
    },
    getCampaignUrl: (state) => {

        let campaign = state.campaign

        if (!isEmpty(campaign)) {

            let url = ""
            if (campaign.name) url += `?mtm_campaign=${encodeURIComponent(campaign.name)}`
            if (campaign.key) url += `&mtm_kwd=${encodeURIComponent(campaign.key)}`
            return url
        } else return null
    },
    getCampaign: (state) => {
        return state.campaign
    },
    getCampaignTracking: (state) => {
        return {
            campaignTrackingId: state.campaignTrackingId,
            campaignTrackingIdType: state.campaignTrackingIdType,
            campaignTrackingIdExpiryIn: state.campaignTrackingIdExpiryIn
        }
    },
    getReturnById: (state) => id => {
        return state.returns.find(r => r.id === id)
    },
    getReturnStatusName: (state) => status => {
        return state.returnStatuses[status]
    },
    getReturnItemStatusName: (state) => status => {
        return state.returnItemStatuses[status]
    },
    getReasonLabel: (state) => status => {
        return state.returnReasons[status]
    },
    getVehicleByIdentification: (state) => cartProduct => {

        let carIdentification = cartProduct?.product?.carIdentification
        return state.vehicles.find(v => {
            if (carIdentification?.platenumber) {
                return v.platenumber === carIdentification.platenumber
            } else if (carIdentification?.mineType) {
                return v.pastMineType === carIdentification.mineType
            } else if (carIdentification?.ciceroneCode) {
                return v.ciceroneCode === carIdentification.ciceroneCode
            } else return false
        })
    },
    hasVehicle: (state) => vehicle => {
        let foundVehicle = state.vehicles.find(v => {
            if (vehicle.platenumber) {
                return v.platenumber === vehicle.platenumber
            } else if (vehicle.mineType) {
                return v.pastMineType === vehicle.mineType
            } else if (vehicle.ciceroneCode) {
                return v.ciceroneCode === vehicle.ciceroneCode
            } else return false
        })

        return foundVehicle != null

    }
}

const actions = {

    getUserInfo({state, commit}) {

        if (state.user) {
            return state.user
        }

        return UserApi.getUserInfo()
            .then(response => {
                commit('setUser', response)
                return response
            }).catch(e => commit("setError", e))
    },

    async getShopOpeningHours() {
        return await ShopApi.getOpeningHours("ec820c0b-3bf4-48d0-aac2-98db0cbbde11")
    },

    logout({commit}) {

        commit("setSessionExpired", false)
        commit("vehicle/setVehicle", null, {root: true})
        commit("cart/setPendingOrder", null, {root: true})
        commit("cart/empty", null, {root: true})
        commit("setLoading", false)
    },

    async searchMunicipality(_, search) {
        return await AddressApi.searchMunicipality(search)
    },

    async searchStreet(_, search) {
        return await AddressApi.searchStreet(search)
    },

    saveVehicle({state, commit}, vehicle) {

        if (find(state.vehicles, v => v.id === vehicle.id)) return;

        return UserApi.saveVehicle(vehicle).then(response => {
            if (response) commit("saveVehicle", response)
            return response

        }).catch((response) => { throw mapUserInfoError(response) })
    },

    deleteVehicle({commit}, vehicleId) {
        return UserApi.deleteVehicle(vehicleId).then(() => {
            commit("removeVehicle", vehicleId)

        }).catch((response) => {
            throw mapUserInfoError(response)

        })
    },

    saveBillingAddress({commit}, address) {
        return UserApi.saveBillingAddress(address).then(response => {
            if (response) commit("saveBillingAddress", response)
            return response

        }).catch((response) => {
            throw mapUserInfoError(response)

        })
    },

    saveUserInfo({commit}, user) {
        return UserApi.saveUserInfo(user).then(response => {
            if (response) commit("saveUserInfo", response)
            return response

        }).catch((response) => {
            throw mapUserInfoError(response)
        })
    },

    async addReturn({commit}, articleReturn) {

        try {

            let response = await ReturnsApi.add(articleReturn)
            commit("addReturn", response)

        } catch (e) {
            throw "Erreur technique, veuillez réessayer"
        }
    },

    async loadReturns({commit}, status) {

        try {

            let response = await ReturnsApi.findAllReturns(status)
            commit("setReturns", response)
            return response

        } catch (e) {
            throw "Erreur technique, veuillez réessayer"
        }
    },

    async cancelReturn({commit}, returnId) {

        try {

            let response = await ReturnsApi.cancelReturn(returnId)
            commit("removeReturn", returnId)
            return response

        } catch (e) {
            throw "Erreur technique, veuillez réessayer"
        }
    },

    async loadVouchers({commit}) {

        try {

            let response = await VouchersApi.loadVouchers()
            commit("setVouchers", response)
            return response

        } catch (e) {
            throw "Erreur technique, veuillez réessayer"
        }
    },

    async answerSurvey({commit}, payload) {
        const response = await UserApi.answerSurvey(payload);
        commit("setSurveyAnswered")
        return response
    }
}

function mapUserInfoError(error) {
    switch(error) {
        case 409:
            return "Cet email existe déjà"
        default:
            return 'Erreur technique, veuillez réessayer'

    }
}

const mutations = {

    setCampaign(state, campaign) {
      state.campaign = campaign
    },
    setCampaignTrackingId(state, payload) {
        state.campaignTrackingId = payload?.campaignTrackingId
        state.campaignTrackingIdType = payload?.campaignTrackingIdType
        state.campaignTrackingIdExpiryIn = payload?.campaignTrackingIdExpiryIn
    },
    setLoading(state, status) {
        if (status) state.error = null
        state.loading = status
    },

    setError(state, error) {
      state.error = error
    },

    setUser(state, user) {
        state.user = user
        state.vehicles = user && user.vehicles ? user.vehicles : []
    },
    saveBillingAddress(state, address) {
        state.user.billingAddress = address
    },
    saveUserInfo(state, user) {
        state.user = user
    },
    saveVehicle(state, vehicle) {

        let vehicles = state.vehicles
        let index = findIndex(vehicles, v => v.id === vehicle.id)
        if (index === -1) {
            vehicles.push(vehicle)
        }
        state.vehicles = cloneDeep(vehicles)
    },
    removeVehicle(state, vehicleId) {
        let vehicles = state.vehicles
        let index = findIndex(vehicles, v => v.id === vehicleId)
        if (index !== -1) {
            vehicles.splice(index, 1)
            state.vehicles = cloneDeep(vehicles)
        }
    },
    addReturn(state, article) {
        let returns = state.returns
        let index = findIndex(returns, v => v.id === article.id)
        if (index === -1) {
            returns.push(article)
        }
        state.returns = cloneDeep(returns)
    },
    setReturns(state, returns) {
        state.returns = returns
    },
    removeReturn(state, returnId) {
        state.returns = state.returns.filter(r => r.id !== returnId)
    },
    setVouchers(state, vouchers) {
        state.vouchers = vouchers
    },
    setSessionExpired(state, value) {
        state.sessionExpired = value
    },
    setSurveyAnswered(state) {
        state.surveyAnswered = true
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
