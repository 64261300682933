<template>
  <div class="form-container" ref="form">
    <v-flex flex-column>
      <label for="platenumber-search" class="platenumber-label">Entrez l'immatriculation de votre véhicule</label>
      <div class="field-set">
        <div data-country="F" class="input-plate">
          <input id="platenumber-search" placeholder="AA-789-BB" v-model="plateNumber"
                 v-on:keyup.enter="selectVehicleByPlateNumber"
                 v-on:keyup="handleInput"
                 />
          <div v-if="error" style="color: #FFFFFF; text-align: center; margin-top: 3px">{{ errorMessage ?? "Champ obligatoire" }}</div>
        </div>
        <dpa-btn class="plate-button" color="primary"
                 value="OK" :loading="loading"
                 @click="selectVehicleByPlateNumber"/>
      </div>
    </v-flex>
  </div>
</template>

<script>
import DpaBtn from "@/components/Button";

export default {
  components: {DpaBtn},
  data() {
    return {
      plateNumber: null,
      loading: false,
      isDeleting: false,
      error: null,
      errorMessage: null
    }
  },
  watch: {
    plateNumber(val, oldVal) {
      this.isDeleting = (oldVal?.length ?? 0) - (val?.length ?? 0) === 1
    }
  },
  methods: {
    handleInput(event) {

      this.error = false
      let value = event.target.value.toUpperCase();
      let length = value.length;
      let keyCode = this.computeKeyCode(event.keyCode, value.slice(-1))
      let ancientFormat = /^\d/.test(value)

      // Space key
      if (keyCode === 32) this.plateNumber = value.slice(0, -1)

      // Delete key
      else if (keyCode === 8) {
        if (this.plateNumber.endsWith("-")) {
          this.plateNumber = value.slice(0, -1)

        } else {
          this.plateNumber = value
        }

        // Dash key
      } else if (keyCode === 189 || keyCode === 109) {

        if (length !== 3 && length !== 7) {
          this.plateNumber = value.slice(0, value.length - 1);
        } else {
          this.plateNumber = value
        }

      } else {

        if (ancientFormat) this.computeOldFormat(length, value)
        else this.computeNewFormat(length, value)



      }
    },
    computeKeyCode(code, value) {

      if (code) return code
      else if (value === "-") {
        return 189
      } else if (value === " ") {
        return 32
      } else if (this.isDeleting) {
        return 8
      }

    },
    computeOldFormat(length, value) {
        this.plateNumber = value
    },
    computeNewFormat(length, value) {
      if (length === 2 || length === 6) {
        this.plateNumber = value + "-"

      } else if (length === 3 || length === 7) {

        let endsWith = this.plateNumber.endsWith("-");

        if (!endsWith) {
          let beggining = value.slice(0, length - 1);
          let lastCharacter = value.slice(length - 1);

          this.plateNumber = beggining + "-" + lastCharacter
        }

      } else {
        this.plateNumber = value
      }
    },
    selectVehicleByPlateNumber() {

      if (!(this.plateNumber && this.plateNumber.length >= 7)) this.error = true
      else {

        this.error = false
        this.loading = true
        this.$store.dispatch("vehicle/checkVehicle", {
          id: this.plateNumber,
          type: 'PLATENUMBER'

        }).then(vehicle =>
            this.$emit("on-result", vehicle)

        ).catch((error) => {
          this.error = true
          if (error.status === 429) {
            this.errorMessage = "Erreur, veuillez réessayer plus tard"
          } else {
            this.errorMessage = "Une erreur est survenue"
          }

        }).finally(() => this.loading = false)

      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

  .form-container {

    label {
      padding: 0 0 0.5em 0;
    }

    .field-set {
      display: flex;
      height: 100%;

      .v-input {
        margin-left: 0.5em;
      }

      .input-plate:before {
        content: attr(data-country);
        position: absolute;
        left: 7px;
        bottom: .125rem;
        width: 2rem;
        text-align: center;
        font-size: 1rem;
        color: white;
      }

      .input-plate {
        height: 50px;
        flex-grow: 1;
        margin-right: 1rem;
        padding: 0 2em 0 2.5em;
        background: #144880 url(../../assets/starsOfEurope.svg) no-repeat 17px 12px/14px 14px;
        border-radius: .5rem;
        position: relative;


        input {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          outline: none;
          background: #FFFFFF;
          box-sizing: border-box;
          padding-left: 1em;
          border: 0;
          text-align: center;
          text-transform: uppercase;
          border-radius: 0;
          letter-spacing: 2px;
          font-size: larger;
        }
      }

    }

    .input-mine {
      background: #FFFFFF;
      box-sizing: border-box;
      padding-left: 1em;
      border: 0;
      text-align: center;
      text-transform: uppercase;
      border-radius: .5rem;
      letter-spacing: 2px;
      font-size: larger;
      outline: none;
      height: 50px;
      min-width: 200px;
      margin-right: 1em;
    }

    .plate-button {
      height: auto;
    }
  }

@media only screen and (max-width: 1000px) {

  #platenumber-search {
    padding: 0;
  }

  .platenumber-label {
    text-align: center;
  }

}
</style>
