import {ShopApi} from "@/api";
import {ShopState, ShopsMutation} from "@/store/types/store-types";
import {Shop, OpeningHours} from "shared-model";
import { groupBy } from "lodash-es";
import { DayOfWeek } from "shared-model"

const SHOP_ID = "ec820c0b-3bf4-48d0-aac2-98db0cbbde11"

const state = () => ({
    shop: null,
})

const getters = {
    getOpeningHours: (state: ShopState) => {
        const openingHours = state.shop?.openingHours ?? []
        let text = ""
        const groupedOpeningHours = groupBy(openingHours, 'asString')
        Object.keys(groupedOpeningHours).forEach((range) => {
            if (range !== "Fermé") {
                const dayRange = computeDayRange(groupedOpeningHours[range])
                text += `${dayRange}\n${range}\n`
            }
        })

        return text
    },
    getPhone: (state: ShopState) => {
        const phone = state.shop?.phone
        return phone?.match(/.{1,2}/g)?.join(' ');
    },
}

function computeDayRange(openingHours: Array<OpeningHours>) {
    const days = openingHours.map(o => o.day)
    const firstDay = days[0].toString()

    if (days.length === 1) return DayOfWeek.fromString(firstDay)
    else {
        const lastDay = days[days.length - 1].toString()
        return `${DayOfWeek.fromString(firstDay)} au ${DayOfWeek.fromString(lastDay)}`
    }
}


const actions = {

    async getShop({commit}: {commit: any}): Promise<Shop> {
        const store = await ShopApi.getShop(SHOP_ID)
        commit(ShopsMutation.SET_SHOP, store)
        return store
    },

    async getOpeningHours(): Promise<String> {
        return await ShopApi.getOpeningHours(SHOP_ID)
    }
}

const mutations = {

    setShop(state: ShopState, shop: Shop) {
        state.shop = shop
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
