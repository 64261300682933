<template>
  <div :class="quantitySelectorClass">
    <i @click="decreaseQuantity" class="prepend mdi mdi-minus"></i>

    <div class="value-content">
      <v-progress-circular v-if="loading" indeterminate size="15" color="#4a4949"></v-progress-circular>
      <input v-else v-model="insideQty" readonly="readonly"/>
    </div>
    <i @click="increaseQuantity" class="append mdi mdi-plus"></i>
  </div>
</template>

<script>
export default {
  name: 'QuantitySelector',
  props: {
    quantity: Number,
    productRef: Object,
    alignRight: Boolean,
    loading: Boolean
  },
  data() {
    return {
      insideQty: this.quantity
    }
  },
  watch: {
    quantity(val) {
      this.insideQty = val
    }
  },
  methods: {
    increaseQuantity() {
      //this.insideQty += 1
      this.quantityChanged(1)
    },
    decreaseQuantity() {
      if (this.insideQty > 1) {
        //this.insideQty -= 1
        this.quantityChanged(-1)
      }
    },
    quantityChanged(quantity) {
      this.$emit('on-quantity-changed', Object.assign({
        quantity: quantity
      }, this.productRef))
    }
  },
  computed: {
    quantitySelectorClass() {
      let clazz = "quantity-selector-container"
      if (this.alignRight) clazz += " align-right"
      return clazz
    }
  }
}
</script>

<style lang="scss">

@import "src/styles/colors";

.quantity-selector-container {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;


  align-items: center;

  i {
    font-size: 15px;
    background-color: $primary-color;
    color: black;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i.append {
    font-size: 15px;
  }

  i.prepend {
    font-size: 15px;
  }

  .value-content {
    width: 30px;
    justify-content: center;
    display: flex;

    input {
      display: block;
      width: 30px;
      text-align: center;
      outline: none;
      user-select: none;
      user-focus: none;
    }
  }

}

.quantity-selector-container.align-right {
  justify-content: flex-end;
}
</style>
