<template>
    <v-btn :icon="buttonIcon"
           :class="buttonClass"
           :type="type"
           :color="color"
           :loading="isLoading" elevation="0"
           @click="click" :disabled="disabled" :text="text" :block="wide">
    {{value}}
    <slot />
    <template v-slot:loader>
      <v-progress-circular indeterminate size="19"></v-progress-circular>
    </template>
  </v-btn>

</template>

<script>
    export default {
        name: 'DpaBtn',
        props: {
          buttonIcon: Boolean,
            value: String,
            wide: Boolean,
            disabled: Boolean,
            color: String,
            rounded: Boolean,
            roundedRight: Boolean,
            roundedLeft: Boolean,
            flat: Boolean,
            loading: Boolean,
            icon: String,
            text: Boolean,
          type: String

        },
        data() {
          return {
            isLoading: this.loading
          }
        },
        watch: {
          loading(val) {
            this.isLoading = val
          }
        },
      methods: {
        click() {
          this.$emit('click')
        }
      },
      computed: {
        buttonClass() {
            let clazz = "dpa-btn"
            if (this.isLoading) clazz += ` loading`
            if (this.color) clazz += ` ${this.color}`
            if (this.disabled) clazz += ` disabled`
            return clazz
        }
      }
    }
</script>

<style lang="scss">

    @import "../styles/colors.scss";

    .dpa-btn.v-btn.v-btn--disabled {
      background-color: gainsboro !important;
    }

    .dpa-btn.disabled {
      background-color: gray !important;
    }

    .dpa-btn.v-btn.facebook {
        background-color: $blue-facebook
    }

    .dpa-btn.v-btn.facebook:hover {
        background-color: $blue-facebook-darker
    }

    .dpa-btn.v-btn.primary {
      background-color: $primary-color !important;
      color:#FFFFFF !important;
    }

    .dpa-btn.v-btn.primary.loading {
      background-color: $app-bar-color !important;
      color: #FFFFFF !important;
    }

    .dpa-btn.v-btn.primary:hover, .dpa-btn.v-btn.primary:focus {
      //background-color: $primary-color-dark;
      background-color: $app-bar-color !important;
      color: #FFFFFF !important;
    }

    .dpa-btn.v-btn.orange {
        background-color: $orange;
    }

    .dpa-btn.v-btn.orange:hover, .dpa-btn.v-btn.orange:focus {
        background-color: $orange-darker;
    }

    .dpa-btn.v-btn.red {
        background-color: $red;
    }

    .dpa-btn.v-btn.red:hover, .dpa-btn.v-btn.red:focus {
        background-color: $red-darker;
    }

    .dpa-btn.v-btn.yellow {
        background-color: $yellow !important;
        color: $text-color;
    }

    .dpa-btn.v-btn.yellow:hover, .dpa-btn.v-btn.yellow:focus {
        background-color: $yellow-darker !important;
    }

    .dpa-btn.v-btn.facebook {
        background-color: $blue-facebook;
    }

    .dpa-btn.v-btn.facebook:hover, .dpa-btn.v-btn.facebook:focus {
        background-color: $blue-facebook-darker;
    }

    .dpa-btn.v-btn.white {
      background-color: $white !important;
      color: #111111 !important;
    }

    .dpa-btn.v-btn.white:hover, .dpa-btn.v-btn.white:focus {
      background-color: $light-gray !important;
    }

</style>