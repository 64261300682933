import axios from 'axios'

export default {

    async add(articleReturn) {
        try {
            let response = await axios.post("/returns", articleReturn)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async findAllReturns(status) {
        try {
            let url = "/returns/user";
            if (status) url += `?status=${status}`
            let response = await axios.get(url)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async cancelReturn(returnId) {
        try {
            let url = `/returns/${returnId}/cancel`;
            let response = await axios.delete(url)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    }
}
