<template>
  <v-form class="form-container">
    <v-flex class="field-set" flex-column>
      <label class="car-model-label">Sélectionnez le modèle de votre véhicule</label>
      <v-flex class="field-set car-model">
        <v-autocomplete label="Marque"
                        v-model="selectedBrand"
                        :items="filteredBrands"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="brandSearch"
                        hide-no-data
                        type="text"
                        background-color="white"
                        color="primary"
                        autocomplete="off"
                        :loading="brandLoading"
                        aria-autocomplete="none"
                        solo cache-items @change="selectBrand"/>

        <v-autocomplete label="Modèle"
                        v-model="selectedModel"
                        :items="filteredModels"
                        item-text="name"
                        item-value="id"
                        background-color="white"
                        color="primary"
                        clearable
                        hide-no-data
                        :loading="modelLoading"
                        :search-input.sync="modelSearch"
                        :disabled="modelSelectEnabled"
                        @change="selectModel"
                        solo />

        <v-autocomplete label="Motorisation"
                        v-model="selectedEngine"
                        :items="filteredEngines"
                        :search-input.sync="engineSearch"
                        item-text="name"
                        item-value="id"
                        background-color="white"
                        color="primary"
                        hide-no-data
                        :loading="engineLoading"
                        :disabled="engineSelectEnabled"
                        @change="selectEngine"
                        solo />
      </v-flex>
    </v-flex>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      brands: [],
      brandSearch: null,
      filteredBrands: [],
      selectedBrand: null,
      brandLoading: false,

      models: [],
      modelSearch: null,
      filteredModels: [],
      selectedModel: null,
      modelLoading: false,

      engines: [],
      engineSearch: null,
      filteredEngines: [],
      selectedEngine: null,
      engineLoading: false,
    }
  },
  computed: {

    modelSelectEnabled() {
      return !this.selectedBrand
    },
    engineSelectEnabled() {
      return !this.selectedModel
    }
  },
  watch: {
    brandSearch(val) {
      val && val !== this.selectedBrand && this.queryBrands(val)
    },
    modelSearch(val) {
      val && val !== this.selectedModel && this.queryModels(val)
    },
    engineSearch(val) {
      val && val !== this.selectedEngine && this.queryEngines(val)
    }
  },
  methods: {
    queryBrands(v) {
      this.filteredBrands = this.brands.filter(b => (b.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1)

    },
    queryModels(v) {
      if (this.selectedModel || v == null || v === "") {
        this.filteredModels = this.models
      } else {
        this.filteredModels = this.models.filter(b => (b.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1)
      }
    },
    queryEngines(v) {
      if (this.selectedEngine || v == null || v === "") {
        this.filteredEngines = this.engines
      } else {
        this.filteredEngines = this.engines.filter(b => (b.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1)
      }

    },
    async selectBrand(brandId) {

      this.selectedBrand = brandId

      this.models = []
      this.filteredModels = []
      this.selectedModel = null
      this.modelSearch = null

      this.engines = []
      this.filteredEngines = []
      this.selectedEngine = null
      this.engineSearch = null

      try {
        this.modelLoading = true
        let models = await this.$store.dispatch("vehicle/getBrandModels", brandId)
        this.filteredModels = models
        this.models = models
      } catch (e) {
        this.error = "Erreur"
      } finally {
        this.modelLoading = false
      }
    },
    async selectModel(modelId) {

      this.selectedModel = modelId
      this.selectedEngine = null
      this.engineLoading = true

      try {
        let engines = await this.$store.dispatch("vehicle/getCarSubmodels", {
          brandId: this.selectedBrand,
          modelId: modelId
        })
        this.filteredEngines = engines
        this.engines = engines

      } catch (e) {
        this.error = "Erreur"
      } finally {
        this.engineLoading = false
      }
    },
    async selectEngine(engineId) {
      this.selectedEngine = engineId

      try {
        this.loading = true
        let vehicle = await this.$store.dispatch("vehicle/checkVehicle", {
          id: engineId,
          type: 'MODEL'
        })

        this.$emit("on-result", vehicle)
      } catch (e) {
        this.error = "Erreur"
      } finally {
        this.loading = false
      }
    },
  },
  async beforeMount() {
    this.brandLoading = true
    let brands = await this.$store.dispatch("vehicle/loadBrands")
    this.brandLoading = false
    this.filteredBrands = brands
    this.brands = brands
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

.form-container {

  label {
    padding: 0 0 0.5em 0;
  }

  .field-set {
    display: flex;
    height: 100%;

    .v-input {
      margin-left: 0.5em;
    }
}
}
@media only screen and (max-width: 1000px) {
  .car-model-label {
    text-align: center;
  }
}

@media only screen and (max-width: 700px) {

  .form-container {
    .field-set .car-model {
      flex-direction: column;
      margin-left: 0;
    }

    .field-set {
      .v-input {
        margin-left: 0;
      }
    }
  }

}



.car-model-label {
  text-align: center;
}

</style>
