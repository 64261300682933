<template>
  <div :class="blockClass" @click="$emit('click')">
    <div v-if="!noHeader" class="block-header">
      <h5>{{title}}</h5>
      <div class="block-header-actions">
        <slot name="header-action" />
        <v-icon @click="toggleExpand" v-if="expandable">{{ expandedIcon }}</v-icon>
      </div>
    </div>

    <slot />
    <slot name="content" />
    <div v-if="isExpanded" class="content-expanded">
      <slot name="content-expanded" />
    </div>
    <div v-if="!noFooter" class="footer-actions">
      <slot  name="footer-actions"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    expandable: Boolean,
    expanded: Boolean,
    noPadded: Boolean,
    noMargin: Boolean,
    noHeader: Boolean,
    noFooter: Boolean,
    noBorder: Boolean,
    noShadow: Boolean,
    clazz: String
  },
  data() {
    return {
      isExpanded: this.expanded
    }
  },
  watch: {
    expanded(val) {
      this.isExpanded = val
    }
  },
  computed: {
    expandedIcon() {
      return this.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
    },
    blockClass() {
      let clazz = "block"
      if (this.noPadded) clazz += " no-padded"
      if (this.noMargin) clazz += " no-margin"
      if (this.noBorder) clazz += " no-border"
      if (this.noShadow) clazz += " no-shadow"
      if (this.clazz) clazz += ` ${this.clazz}`
      return clazz
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style lang="scss" scoped>

.block {

  background-color: white;
  margin: 0 32px 32px 32px;
  padding: 1em;
  box-shadow: 0 3px 6px rgb(0 0 0 / 6%);

  .block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .footer-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }


}

.block.no-padded {
  padding: 0;
  .block-header {
    padding: 1em;
  }
}

.block.no-margin {
  margin: 0;
}

.block.no-border {
  border: none;
}

.block.no-shadow {
  box-shadow: none;
}

.block.fullwidth {
  padding: 0;

  .block-header {
    padding: 1em;
  }
}

@media only screen and (max-width: 1000px) {

  .block {
    margin: 0 0 16px 0;
  }

}

</style>
