import axios from 'axios'

export default {

    async validateVoucher(voucherCode, cartId) {
        try {
            let response = await axios.post("/vouchers/validate", {voucherCode, cartId})
            return response ? response.data : null
        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            throw 500
        }
    },

    async loadVouchers() {
        try {
            let response = await axios.get(`/vouchers/user`)
            return response ? response.data : null
        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            throw 500
        }
    }
}