import Vue from "vue";
import * as Filters from './filters'
import {CarApi, CatalogApi} from "@/api";
import {cloneDeep, flatten, groupBy, isEmpty, isEqual, reduce, unionBy} from "lodash-es"

const state = () => ({
    brands: [],
    suppliers: [],
    categories: [],
    products: [],
    exhaustProducts: [],
    searchFilters: [],
    flattenCategories: [],
    filteredProducts: [],
    product: null,
    error: null,
    isLoading: false,
    filter: {
        search: '',
        status: 'all',
        order: 'createdAt'
    },

    exhaustSearch: false,
    searchPending: false,
    searchTimestamp: null,
    searchResults: {},
    searchParams: null
})

const getters = {

    getCategories: state => {
        return state.categories
    },
    isExhaustSearch: state => {
        return state.exhaustSearch
    },
    getProducts: state => {
        return state.products
    },
    getExhaustProducts: state => {
        return state.exhaustProducts
    },
    getProductByReference: state => (reference) => {
        let filtered = state.products.filter(p => p.reference === reference)
        return filtered.length > 0 ? filtered[0] : null
    },
    getCategoryNameById: (state, getters) => (categoryId) => {
        let cat = getters.getCategoryByUniqueId(categoryId);
        return cat ? cat.name : null

    },
    getComputedCategory: (state) => (subCategoryId, criterionId) => {
        let category = state.flattenCategories.find(c => c.subCategoryId === subCategoryId && c.criterionId === criterionId)
        return `${category.subCategoryName}/${category.criterionName}`
    },
    getFlatCategory: (state, getters) => (subCatName, criterionName) => {
        let flattenCategory = state.flattenCategories.filter(c => {

            if (!criterionName) {
                return getters.getCategoryNameAsURI(c.subCategoryName) === subCatName
            }

            return (getters.getCategoryNameAsURI(c.criterionName) === criterionName &&
                getters.getCategoryNameAsURI(c.subCategoryName) === subCatName)
        })[0];
        return flattenCategory ? flattenCategory : {}
    },
    getCategoryByUniqueId: (state) => (id) => {
        return findCategory(state.categories, id);
    },
    getCategoryNameAsURI: () => (name) => {
        return name.replace(/\s+/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\//g, '').toLowerCase()
    },
    getURLPathForCriterion: (state, getters) => (criterionId, subCategoryId) => {
        let criterion = state.flattenCategories.filter(c => {
            return c.criterionId === parseInt(criterionId) && c.subCategoryId === parseInt(subCategoryId)
        })[0]

        return criterion ? `${getters.getCategoryNameAsURI(criterion.subCategoryName)}/${getters.getCategoryNameAsURI(criterion.criterionName)}` : null
    },
    getCategoriesAsTreeView: () => (categories) => mapCategories(categories),

    getBrandImage: (state) => (brandId) => {
        return state.brands.filter(id => brandId === id)
    },
    filterCategories: (state) => (search) => {
        let normalizedSearch = normalize(search)

        let filteredCAtegories = state.flattenCategories.filter(c => contains(c.criterionName, normalizedSearch) ||
            contains(c.subCategoryName, search) ||
            contains(c.categoryName, search)
        );

        return mapCategoriesAsTree(filteredCAtegories)
    },
    getBrandLogo: (state) => (brandId) => {
        let brand = state.brands.find(b => b.brandId === brandId);
        return brand ? brand.logoUrl : null
    },
    isSameSearch: (state) => (searchParams) => {
        return isEqual(state.searchParams, searchParams)
    }
}

function normalize(string) {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

function contains(item, search) {
    if (!item) return false
    else return normalize(item).toLowerCase().indexOf(search.toLowerCase()) !== -1
}

const actions = {

    async filterSearch({commit}, search) {
        await commit('setFilterSearch', search)
        await commit("filterProducts")
    },

    loadCategories({commit}) {

        return CatalogApi.getCategories().then(categories => {
            commit('setCategories', categories)
            return categories

        }).catch(() => {
            commit('setError', 'Erreur technique, veuillez réessayer')
            return []

        })

    },

    searchByReference({commit, state}, search) {

        let requestTimestamp = new Date().valueOf();
        commit("setSearchPending", {pending: true, timestamp: requestTimestamp})
        return CatalogApi.searchByReference(encodeURIComponent(search)).then(response => {

            if (state.searchTimestamp <= requestTimestamp) {
                commit("setSearchResults", {
                    reference: response,
                })
            }
            return response
        }).catch(() => {
            commit('setError', 'Erreur technique, veuillez réessayer')
        }).finally(() => commit("setSearchPending", {pending: false}))
    },

    searchCategories({commit, getters}, search) {

        if (search.length >= 2) {
            let filteredCategories = getters.filterCategories(search)
            if (filteredCategories.length > 0) {
                commit('setSearchResults', {
                    categories: filteredCategories
                })
            }

        }
    },

    async checkProductCompatibility(_, payload) {
        try {
            return await CatalogApi.checkProductCompatibility(payload)
        } catch (e) {
            throw "Impossible de vérifier la compatibilité."
        }
    },

    async searchProducts({state, commit, getters, rootGetters}, {subCategoryId, criterionId, brandId, modelId, carCode, platenumber, carKey, mineType, year}) {

        let isExhaustSearch = subCategoryId == "3" && criterionId == "11";
        if (getters.isSameSearch({subCategoryId, criterionId, brandId, modelId, carCode, platenumber, carKey, mineType, year})) {
            return isExhaustSearch ? state.exhaustProducts : state.products
        }

        commit('setSearchParams', {subCategoryId, criterionId, brandId, modelId, carCode, platenumber, carKey, mineType, year})
        commit('resetSearch', {subCategoryId, criterionId})
        commit("setLoading", true)

        try {

            if (!isEmpty(platenumber) && carKey == null) {

                try {
                    const carResponse = await CarApi.getCarDetailsByPlatenumber(platenumber,
                        rootGetters["session/getCampaignTracking"])
                    carKey = carResponse.carKey
                } catch (e) {
                    carKey = null
                }
            }

            const response = isExhaustSearch
                ? await CatalogApi.searchExhaust(subCategoryId, criterionId, brandId, modelId, carCode, carKey, mineType, year)
                : await CatalogApi.searchProducts(subCategoryId, criterionId, brandId, modelId, carCode, carKey, mineType, year)

            // filter for strange price in production
            let filteredProducts = []
            if (!isExhaustSearch) {
                filteredProducts = response.products.filter(p => {
                    return process.env.VUE_APP_ENV !== 'production' ||
                        ((p.publicPrice === 0 || p.publicPrice > p.price) && p.price > 0)
                })
                commit('setSuppliers', filteredProducts)
                commit('setProducts', filteredProducts)

            } else {

                const filteredExhaustLine = response.products.map((exhaustLine) => {

                    exhaustLine.products = exhaustLine.products.filter(p => process.env.VUE_APP_ENV !== 'production' ||
                        ((p.publicPrice === 0 || p.publicPrice > p.price) && p.price > 0))

                    return exhaustLine.products.length === 0 ? null : exhaustLine

                }).filter(m => m != null)

                filteredProducts = flatten(reduce(filteredExhaustLine, (result, value) => {
                    result.push(value.products)
                    return result
                }, []));

                commit('setExhaustProducts', filteredExhaustLine)
            }

            commit('setSuppliers', filteredProducts)
            commit('setSearchFilters', response.filters)

            filteredProducts.forEach(p => {
                let category = getters.getComputedCategory(subCategoryId, criterionId)
                Vue.prototype.$matomo.setEcommerceView(
                    p.supplierReference,
                    p.productName,
                    [category],
                    p.price
                );
            })

            return response

        } catch (e) {
            throw "Erreur technique, veuillez réessayer"

        } finally {
            commit("setLoading", false)
        }
    },

    loadProduct({commit}, {reference, supplierCode}) {

        commit("setLoading", true)

        return CatalogApi.loadProduct(reference, supplierCode).then(response => {
            commit('setProduct', response)
            return response

        }).catch(() => {
            throw 'Erreur technique, veuillez réessayer'
        }).finally(() => commit("setLoading", false))


    }
}

const mutations = {

    setFilterSearch(state, search) {
        state.filter.search = search
    },
    filterProducts(state) {
        const products = [...state.products]
        state.filteredProducts = products
        state.filteredProducts = Filters.filterProducts(state.filter, products)
    },
    setCategories(state, categories) {

        let enhancedCategories = categories.map(category => {
            category.img = mapIcon(category.categoryId).img
            return category
        })

        state.flattenCategories = cloneDeep(enhancedCategories)
        state.categories = mapCategoriesAsTree(enhancedCategories)
    },
    resetSearch(state, {subCategoryId, criterionId}) {
        state.exhaustSearch = subCategoryId === 3 && criterionId === 11
        state.products = []
        state.exhaustProducts = []
        state.searchFilters = []
    },
    setSearchParams(state, payload) {
      state.searchParams = payload
    },
    setSearchFilters(state, filters) {
      state.searchFilters = filters
    },
    setProducts(state, products) {
        state.products = products
    },
    setExhaustProducts(state, products) {
        state.exhaustProducts = products
    },
    setProduct(state, product) {
        state.product = product
    },
    setLoading(state, status) {
        if (status) state.error = null
        state.isLoading = status
    },
    setError(state, message) {
        state.error = message
    },

    setSearchPending(state, data) {
        state.searchPending = data.pending
        if (data.pending) {
             state.searchTimestamp = data.timestamp
        } else {
            state.searchTimestamp = null
        }
    },

    setSearchResults(state, results) {
        state.searchResults = results
    },

    emptySearchResults(state) {
        state.searchResults = {}
    },

    setSuppliers(state, results) {
        state.suppliers = unionBy(results.map(p => Object.assign({}, {
            id: p.supplierId,
            name: p.supplierName,
            logoUrl: p.supplierLogoUrl
        })), 'id')
    }
}

function mapCategories(categories, parentId) {

    if (categories == null) return undefined

    let type = parentId ? 'subcat' : "cat"
    return categories.map(category => {
        return {
            id: category.id,
            uniqueId: parentId ? `${type}-${category.id}-${parentId}` : `${type}-${category.id}`,
            name: category.name,
            parentId: parentId,
            children: mapCategories(category.subCategories, category.id)
        }
    })
}

function mapCategoriesAsTree(categories) {
    let groupByCategory = groupBy(categories, 'categoryId');
    return Object.entries(groupByCategory).map(([cKey, cValue], i) => {
        let medias = mapIcon(cKey);
        return {
            id: cKey,
            icon: medias.icon,
            img: medias.img,
            name: cValue[0].categoryName,
            uniqueId: `cat-${i}`,
            subCategories: Object.entries(groupBy(cValue, 'subCategoryId')).map(([scKey, scValue], j) => {
                return {
                    id: scKey,
                    parentId: cKey,
                    name: scValue[0].subCategoryName,
                    uniqueId: `subcat-${i}-${j}`,
                    subCategories: Object.entries(groupBy(scValue, 'criterionId')).map(([crKey, crValue], k) => {
                        return {
                            id: crKey,
                            parentId: scKey,
                            name: crValue[0].criterionName,
                            uniqueId: `criterion-${i}-${j}-${k}`,
                        }
                    })
                }
            })
        }
    })
}

function mapIcon(key) {
    switch (key) {
        case "001":
            return {
                img: "breaks_category.webp",
                icon: "052-break.svg"
            }
        case "002":
            return {
                img: "filtre_category.webp",
                icon: "icone_filtration.png"
            }
        case "003":
            return {
                icon: "060-suspension.svg",
                img: "suspension_category.webp"
            }
        case "004":
            return {
                icon: "002-temperature.svg",
                img: "allumage_category.webp"
            }
        case "005":
            return {
                icon: "056-exhaust.svg",
                img: "exhaust_category.webp"
            }
        case "006":
            return {
                icon: "059-engine.svg",
                img: "timingbelt_category.webp"
            }
        case "007":
            return {
                icon: "061-tire.svg",
                img: "tires_category.jpeg"
            }
        case "008":
            return {
                icon: "066-car-lights.svg",
                img: "visibility_category.webp"
            }
        case "009":
            return {
                icon: "049-spark-plug.svg",
                img: "battery_category.webp"
            }
        case "010":
            return {
                icon: "048-gearshift.svg",
                img: "clust_category.webp"
            }
        case "011":
            return {
                icon: "038-car.svg",
                img: "car_turbo_category.webp"
            }
        case "012":
            return {
                icon: "038-car.svg",
                img: "flowmeter_category.webp"
            }
        case "013":
            return {
                icon: "026-air-conditioner.svg",
                img: "airconditioning_category.webp"
            }
        case "014":
            return {
                icon: "icone_rearview.png",
                img: "rearview_category.webp"
            }
        case "015":
            return {
                icon: "icon-yaka-carrosserie.svg",
                img: "car_switch_category.webp"
            }
        case "016":
            return {
                icon: "038-car.svg",
                img: "car_hook_category.webp"
            }
        default:
            return {
                icon: "038-car.svg",
                img: "piece.webp"
            }
    }
}

function findCategory(categories, id) {

    if (categories == null) return undefined

    for (let i = 0, count = categories.length; i < count; i++) {
        let category = categories[i]
        if (category.uniqueId === id) {
            return category
        } else {
            let foundCategory = findCategory(category.subCategories, id)
            if (foundCategory != null) return foundCategory
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
