import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#ff884c',//'#FF7029',//'#F3633B',
                secondary: '#29B6F6',
                accent: '#8c9eff',
                error: '#b71c1c',
                yellow: '#f6b924'
            },
        },
    }
});
