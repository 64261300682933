import Vue from 'vue'
import App from '@/App.vue'

import store from '@/store'
import router from '@/router'

import '@/plugins/moment'
import '@/plugins/matomo'
import vuetify from '@/plugins/vuetify';
import MobileUtils from "@/plugins/mobile-utils";
import DateUtils from "@/plugins/dateUtils";
import ProductUtils from "@/plugins/productUtils";

import '@/main.scss'
import '@/styles/animation.css'
import '@/styles/flexUtils.css'
import '@/styles/parallax.css'
import '@/styles/css/materialdesignicons.css'

Vue.use(DateUtils)
Vue.use(ProductUtils)
Vue.use(MobileUtils)
Vue.config.productionTip = process.env.VUE_APP_ENV === 'production'

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: h => h(App)
})
