<template>
  <v-form class="form-container">
    <v-flex class="field-set" flex-column>
      <label>Sélectionnez un de vos véhicule</label>
      <v-flex>
        <v-select
            label=""
            :value="mappedVehicles[0]"
            :items="mappedVehicles"
            no-data-text="Aucun véhicule"
            item-text="model"
            item-value="id"
            background-color="white"
            color="black"
            @input="selectVehicle"
            solo>

        </v-select>

      </v-flex>
    </v-flex>
  </v-form>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      vehicles(state) {
        return state.session.vehicles
      },
    }),
    mappedVehicles() {
      return this.vehicles.map(v =>  {
        return {
          model: `${v.brandName} ${v.modelName}`,
          id: v.id
        }
      })
    }
  },
  methods: {
    selectVehicle(id) {
      let find = this.vehicles.find(v => v.id === id);
      this.$store.commit("vehicle/setVehicle", find)
      this.$emit("on-result", find)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>