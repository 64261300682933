import {DeliveriesState, DeliveriesMutation, RelayPoint, Address} from "@/store/types/deliveriesTypes";
import {OrderStatus} from "@/store/types/ordersTypes";
import {DeliveryApi} from "@/api"
import {isEqual} from "lodash-es";

const state = () => ({
    error: null,
    relayPoints: [],
    deliveryAddress: null,
    loading: false,
})

const getters = {
    computeDeliveryStatus: () => (status: OrderStatus) => {
        switch (status) {
            case OrderStatus.TO_PREPARE:
            case OrderStatus.CREATED:
            case OrderStatus.PAID:
            case OrderStatus.PLACED:
            case OrderStatus.ORDERED:
            case OrderStatus.ERROR:
            case OrderStatus.TO_DISPATCH:
                return "En cours de préparation"

            case OrderStatus.GIVEN_TO_CARRIER:
                return "Remis au transporteur"

            case OrderStatus.AVAILABLE:
                return "A dispo"

            case OrderStatus.DONE:
                return "Colis récupéré"

            case OrderStatus.CANCELLED:
                return "Livraison annulée"

            default:
                return null
        }
    }
}

const actions = {

    async getNearestRelayPoints({state, commit, rootGetters}: {state: DeliveriesState, commit: any, rootGetters: any},
                                address: Address) {

        if (state.relayPoints.length > 0 && isEqual(state.deliveryAddress, address)) {
            console.log("not loading relays as it exists")
            commit(DeliveriesMutation.SET_RELAY_POINTS, { relays: state.relayPoints, address })
            return state.relayPoints
        }

        commit(DeliveriesMutation.SET_LOADING, true)
        commit(DeliveriesMutation.RESET_RELAY_POINTS)
        try {

            const weight = rootGetters["cart/getOrderWeight"]
            const shippingDate = rootGetters["cart/getOrderAvailabilityDate"]?.toLocaleDateString('fr')
            const relays = await DeliveryApi.getNearestRelayPoints({address, weight, shippingDate})
            commit(DeliveriesMutation.SET_RELAY_POINTS, {relays, address})
            return relays
        } catch (e) {
            throw "Erreur technique. Veuillez réessayer dans quelques instants."
        } finally {
            commit(DeliveriesMutation.SET_LOADING, false)
        }
    },
}

const mutations = {

    resetRelayPoints(state: DeliveriesState) {
        state.relayPoints = []
    },

    setRelayPoints(state: DeliveriesState, {relays, address}: {relays: Array<RelayPoint>, address: Address}) {
        state.relayPoints = relays
        state.deliveryAddress = address
    },

    setError(state: DeliveriesState, error: string) {
        state.error = error
    },

    setLoading(state: DeliveriesState, status: Boolean) {
        if (status) delete state.error
        state.loading = status
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
