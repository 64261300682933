<template>
  <div class="secondary-nav" v-click-outside="onClose">
    <div class="main">
      <div class="secondary-nav-header">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
          <div><h1>Catégories</h1></div>
          <v-btn @click="onClose" icon class="close"><v-icon large>mdi-close</v-icon></v-btn>
        </div>
      </div>
      <div class="secondary-nav-content">
        <ul class="secondary-nav-list" v-if="!displaySubcategories">
          <li class="secondary-nav-item" v-for="(category, i) in categories" :key="`category-${i}`" @click="selectCategory(category)">
            <img :src="require(`@/assets/icons/${category.icon}`)" :alt="category.name" />
            <span>{{category.name}}</span>
            <v-icon class="chevron">mdi-chevron-right</v-icon>
          </li>
        </ul>
        <div v-else>
          <v-breadcrumbs :items="items">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :disabled="item.disabled" link @click.native="move(item)">
                <div :class="item.disabled ? '' : 'link'">{{ item.text }}</div>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <ul class="subcategory-list">
            <li class="subcategory-item"
                v-for="(c, i) in selectedCategory.subCategories"
                v-bind:key="`subcat-${i}`"
                @click="selectCategory(c)">

              {{c.name}}
            </li>
          </ul>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {isEmpty} from "lodash-es";
  import {mapState} from "vuex";

  export default {
    data() {
      return {
        displaySubcategories: false,
        selectedCategory: null,
        items: [{
          id: null,
          text: 'Catégories',
          disabled: false,
          href: '/',
        }]
      }
    },
    computed: {
      ...mapState({
        vehicle(state) {
          return state.vehicle.vehicle
        }
      }),
      categories() {
        return this.$store.getters["catalog/getCategories"]
      }
    },
    methods: {
      move(item) {
        if (item.disabled) return;

        if (item.text === 'Catégories') {
          this.items = [this.items[0]]
          this.selectedCategory = null
          this.displaySubcategories = false

        } else {

          let c = this.items.findIndex(c => c.text === item.text)
          this.items.splice(c + 1)
          this.selectedCategory = this.$store.getters["catalog/getCategoryByUniqueId"](item.id)

        }
      },
      onClose() {
        this.$emit("on-close")
      },

      selectCategory(category) {
        window.scrollTo(0, 0)
        if (category.subCategories == null || category.subCategories.length === 0) {
          let urlName = this.$store.getters["catalog/getURLPathForCriterion"](category.id, category.parentId)

          let url = "/catalogue"
          if (this.vehicle && !isEmpty(this.vehicle)) {
            url += `/v${this.vehicle.url}`
          }

          this.$router.push(`${url}/c/${urlName}`)

        } else {

          this.selectedCategory = category
          this.displaySubcategories = true
          this.items.map(i => {
            i.disabled = false
            return i
          })
          this.items.push({
            id: category.uniqueId,
            text: category.name,
            disabled: true,
            href: ""
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "src/styles/colors";
.close {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.4s;
}
.close:hover {
  opacity: 1;
}
.subcategory-item {
  cursor: pointer;
  padding: 0.3em;
}

.subcategory-item:hover {
  color: $primary-color;
}

.subcategory-list {
  list-style: none;
  margin-left: 1.5em;
}

.secondary-nav {
  position: absolute;
  top: 137px;
  padding: 24px 42px;
  flex-direction: column;
  background-color: white;
  color: $text-color;
  width: 100%;
  z-index: 4;
  box-shadow: 0 20px 20px -20px #e0e0e0;
  border-bottom: 1px solid $border-color;
  box-sizing: border-box;
  min-height: 290px;

  .main {
    max-width: 1280px;
    margin: auto;
    .secondary-nav-header {
      padding: 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .secondary-nav-content {

      height: 70%;
      .secondary-nav-list {
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .secondary-nav-item:hover {
          color: $primary-color;
        }

        .secondary-nav-item {
          display: flex;
          align-items: center;
          padding-top: 13px;
          padding-bottom: 13px;
          width: 300px;
          height: 50px;
          cursor: pointer;
          border-bottom: 0.3px solid #e0e0e0;
          margin-right: 2em;
          margin-bottom: 1em;

          img {
            height: 20px;
            width: 20px;
          }

          span {
            margin-left: 1rem;
          }
          .chevron {
            flex: 3;
            text-align: end;
          }
        }
      }
    }
  }


}



@media only screen and (max-width: 1000px) {
  .secondary-nav {
    top: 94px;
    width: 100%;
    font-size: 16px;
    padding: 0;

    .main {
      .secondary-nav-header {
        padding: 1em 1em 0;
      }

      .secondary-nav-content {

        .secondary-nav-list {

          flex-direction: column;
          flex-wrap: revert;

          width: 100%;

          .secondary-nav-item {
            display: flex;
            align-items: center;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            width: 100%;
            margin-right: 0;
            margin-bottom: 0.5em;
            padding-left: 1em;

            img {
              height: 20px;
              width: 20px;
            }

            span {
              margin-left: 1rem;
            }
            .chevron {
              flex: none;
              text-align: end;
            }
          }
        }
      }
    }
  }
}
</style>
