export enum DayOfWeek {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7
}

export namespace DayOfWeek {
    // eslint-disable-next-line no-inner-declaration
    export function valueOf(str: string): DayOfWeek {
        return DayOfWeek[str as keyof typeof DayOfWeek] as DayOfWeek;
    }
    // eslint-disable-next-line no-inner-declarations
    export function fromString(str: string) {
        const day = DayOfWeek.valueOf(str)
        return DayOfWeek.from(day)
    }
    // eslint-disable-next-line no-inner-declarations
    export function from(id: DayOfWeek) {

        switch (id) {
            case DayOfWeek.MONDAY:
                return "Lundi"
            case DayOfWeek.TUESDAY:
                return "Mardi"
            case DayOfWeek.WEDNESDAY:
                return "Mercredi"
            case DayOfWeek.THURSDAY:
                return "Jeudi"
            case DayOfWeek.FRIDAY:
                return "Vendredi"
            case DayOfWeek.SATURDAY:
                return "Samedi"
            case DayOfWeek.SUNDAY:
                return "Dimanche"
        }
    }
}