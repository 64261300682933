import Vue from 'vue'
import axios from "axios";
import store from "@/store"
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

Vue.use(VueKeyCloak, {
    init: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
    },
    onReady: () => {
        axios.interceptors.request.use(config => {
            if (Vue.prototype.$keycloak.authenticated && config.baseURL === process.env.VUE_APP_BACKEND_HOST) {
                config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
            }
            return config
        }, error => {
            return Promise.reject(error)
        })
    },
    config: {
        url: `${process.env.VUE_APP_IDS_HOST}`,
        realm: 'mpa',
        clientId: 'mpa-web',
        "enable-cors": true,
        flow: "implicit",
        checkLoginIframeInterval: 5,
        enableLogging: true,

    },
    logout: {
        redirectUri: `${process.env.VUE_APP_HOST}/`
    },
    onAuthRefreshError: () => {
        store.commit("session/setSessionExpired", true)
    },
})

export default VueKeyCloak

