import axios from 'axios'

const ADDRESS_API_URL = "https://api-adresse.data.gouv.fr"
export default {

    async searchMunicipality(postalCode) {

        if (!postalCode || postalCode === "") return;

        let url = `/search?type=municipality&autocomplete=1&q=${postalCode}`

        let response = await axios.get(url, {
            baseURL: ADDRESS_API_URL,
        })

        return response ? response.data.features.map(r => {
            return r.properties
        }) : null
    },

    async searchStreet({street, postcode}) {

        if (!street || street === "") {
            return []
        } else {
            // FIXME must change according to user ? or according to shop id ?
            let url = `/search?type=housenumber&autocomplete=1&q=${street}`
            if (postcode) url += `&postcode=${postcode}`
            else url += "&lat=48.527443&lon=2.551557"

            let response = await axios.get(url, {
                baseURL: ADDRESS_API_URL,
            })
            return response ? response.data.features.map(r => {
                return r.properties
            }) : null
        }
    }

}