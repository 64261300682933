export enum OrderStatus {
    CREATED = 'CREATED',
    PAID = 'PAID',
    PLACED = 'PLACED',
    ORDERED = "4ORDERED",
    TO_PREPARE = 'TO_PREPARE',
    TO_DISPATCH = 'TO_DISPATCH',
    GIVEN_TO_CARRIER = 'GIVEN_TO_CARRIER',
    AVAILABLE = 'AVAILABLE',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR'
}
