import axios from 'axios'

export default {

    async getCategories() {
        return new Promise((success, die) => {
            axios.get("/catalog/categories").then(response => {
                if (response.status === 200) {
                    success(response.data)
                }
                die()
            }).catch(die)
        })
    },

    async searchByReference(reference) {
      return new Promise((success, die) => {

          axios.get(`/catalog/products/${reference.replace(/\s/g, "")}/correlations`).then(response => {
              success(response.data)
          }).catch(die)
      })
    },

    async checkProductCompatibility(payload) {
        const url = `/catalog/products/${payload.supplierReference}/compatibility`
        let response = await axios.post(url, payload)
        return response.data
    },

    async searchProducts(subCategoryId, criterionId, brandId, modelId, carCode, carKey, mineType, year) {
        return new Promise((success, die) => {
            let url = `/catalog/products?subCategoryId=${subCategoryId}&criterionId=${criterionId}&brandId=${brandId}&modelId=${modelId}`;

            if (carCode) url += `&carCode=${carCode}`
            if (carKey) url += `&carKey=${carKey}`
            if (mineType) url += `&pastMineType=${mineType}`
            if (year) url += `&year=${year}`

            axios.get(url).then(response => {
                if (response.status === 200) {
                    success(response.data)
                }
                die()
            }).catch(die)
        })
    },

    async searchExhaust(subCategoryId, criterionId, brandId, modelId, carCode, carKey, mineType, year) {
        return new Promise((success, die) => {
            let url = `/catalog/exhaust?subCategoryId=${subCategoryId}&criterionId=${criterionId}&brandId=${brandId}&modelId=${modelId}`;

            if (carCode) url += `&carCode=${carCode}`
            if (carKey) url += `&carKey=${carKey}`
            if (mineType) url += `&pastMineType=${mineType}`
            if (year) url += `&year=${year}`

            axios.get(url).then(response => {
                if (response.status === 200) {
                    success(response.data)
                }
                die()
            }).catch(die)
        })
    },

    async loadProduct(reference, supplierCode) {

        try {

            let url = `/catalog/products/${reference}/correlations`;
            if (supplierCode) url += `?supplierCode=${supplierCode}`

            let response = await axios.get(url)
            return response.data

        } catch (e) {
            throw "Impossible de trouver cette référence"
        }
    },

}
