<template>
  <div :class="displaySearchScreen ? 'search-bar-container open' : 'search-bar-container'">
    <v-text-field autocomplete="off" class="search-input"
                  :placeholder="placeholder" v-model="search"
                  :solo="!$isMobile()" :flat="!$isMobile()" content-class="elevation-0" hide-details>

      <template v-slot:prepend-inner>
        <v-icon class="input-search-icon">mdi-magnify</v-icon>
      </template>

      <template v-slot:append>

      </template>
    </v-text-field>

    <div class="app-bar-item-container">
      <v-progress-circular class="input-loading-icon"
                           v-if="searchPending"
                           color="primary"
                           indeterminate
                           size="20"
      ></v-progress-circular>
    </div>

    <SearchResults v-if="displaySearchScreen"
                   :results="searchResults"
                   :loading="searchPending"
                   @on-close="close"
                   @on-open-ref="close"/>

  </div>
</template>

<script>
import SearchResults from "@/components/SearchResults";
import {mapState} from "vuex";
import {debounce} from "lodash-es"
export default {
  props: {
    placeholder: String
  },
  components: {SearchResults},
  data() {
    return {
      search: "",
      displaySearchScreen: false
    }
  },
  computed: {
    ...mapState({
      searchResults(state) {
        return state.catalog.searchResults
      },
      searchPending(state) {
        return state.catalog.searchPending
      },
    }),
  },
  watch: {
    search(newValue) {
        this.realSearch(newValue)
    },
    searchResults() {
    //  this.displaySearchScreen = !isEmpty(val)
    },
    displaySearchScreen(val) {
      if (val) {
        this.$emit("on-open")
      } else this.$emit("on-close")
    }
  },
  methods: {
    realSearch: async function(search) {

      if (search.length >= 2) {
        await this.$store.dispatch("catalog/searchCategories", search)
        this.displaySearchScreen = true

      }

      if (search.length >= 3)
        this.referenceSearch(search)

      if (search.length < 2) {
        this.$store.commit("catalog/emptySearchResults")
      }

      if (search.length === 0) this.displaySearchScreen = false
    },
    referenceSearch: debounce(async function(search) {
      await this.$store.dispatch("catalog/searchByReference", search)
      this.displaySearchScreen = true
      this.$matomo?.trackEvent("Recherche par référence", search, null, null)
    }, 500),
    open() {
      this.isOpen = true
      this.displaySearchScreen = this.$isMobile()
    },
    close() {
      this.isOpen = false
      this.search = ""
      this.displaySearchScreen = false

    },
  }
}


</script>

<style lang="scss">

@import "src/styles/colors";

.v-input__icon .v-input__icon--prepend-inner {
  padding-left: 1em;
}

.search-input {
  font-size: 16px;
  .v-input__control {
    min-height: auto !important;
  }

  .v-input__slot {
    padding: 1em 0;
  }
}

.app-bar-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  transition: color .35s ease;
  color: #BEBEBF;
  cursor: pointer;

  .menu-icon {
    font-size: xx-large;
    color: #BEBEBF;
  }
}

.app-bar-item-container:hover {
  color: $primary-color;
  .menu-icon {
    color: $primary-color;
  }
}


@media only screen and (max-width: 1000px) {
  .search-input.v-text-field {
    margin-top: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "src/styles/colors";

.search-bar-container {


  height: 38px;
  display: flex;
  align-items: center;
  //transition: all 0.4s;
  border-radius: 10px;
  background: #272727;
  padding: 0;
  z-index: 101;
  box-sizing: border-box;
  width: 300px;

  .search-input {
    width: 0;
    outline: 0;
    border: 0;
    padding: 0 20px;
    border-radius: 20px;
    //transition: all 0.4s;
    background: #272727;
    display: none;

    .input-search-icon {
      padding-left: 0;
    }
  }
}

.search-icon {
  box-sizing: border-box;
  text-align: center;
}

.search-bar-container {
  cursor: pointer;
  background: white;

  .search-input {
    display: block;
  }
}

.search-bar-container .search-input {
  display: block;
  width: 270px;
  background: white;
}

.search-bar-container .search-icon {
  color: $primary-color !important;
  font-size: 26px;
}

@media only screen and (max-width: 1180px) {
  .search-bar-container {
    width: 250px;
  }
  .search-bar-container .search-input {
    width: 250px;
  }

}

@media only screen and (max-width: 1080px) {
  .search-bar-container {
    width: 170px;
  }
  .search-bar-container .search-input {
    width: 170px;
  }

}
@media only screen and (max-width: 1000px) {

  .search-bar-container {
    padding: 0;
    background: transparent;
    color: black;
    width: 100%;
    height: 100%;

    .search-input {
      display: block;
      outline: 0;
      border: 0;
      padding: 0;
      border-radius: 0;
      background: transparent;

      .input-search-icon {
        padding-left: 0.8em;
        margin-right: 0.4em;
      }


    }

    .app-bar-item-container {
      padding: 0;

      .input-loading-icon {
        margin-right: 0.8em;
        margin-left: 0.4em;
      }
    }
  }

  .search-bar-container.open {

    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    border-radius: 0;
    background-color: white;
    height: 64px;

    .search-input {
      padding: 0;

      width: 100%;
      position: absolute;

      .input-search-icon {
        padding-left: 1em;
      }

      .input-loading-icon {
        padding-right: 1em;
      }
    }

    .app-bar-item-container {
      position: absolute;
      top: 13px;
      right: 12px;
      padding: 0.5em 0 0 0;

      i {
        font-size: large;
      }
    }

    .app-bar-item-container:hover {
      color: $primary-color;

      ul {
        .menu-icon {
          color: $primary-color;
        }
      }

    }
  }
}

</style>
