import Vue from "vue";
import VueMatomo from "vue-matomo";
import router from "@/router";

Vue.use(VueMatomo, {
    host: process.env.VUE_APP_ANALYTICS_HOST,
    siteId: 1,
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    requireCookieConsent: true,
    trackInitialView: true,
    trackerFileName: "piwik",
    debug: process.env.VUE_APP_ENV !== 'production'
});
