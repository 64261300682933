<template>
  <div>
    <div class="background-light reviews">

      <div class="page page-content" style="flex-direction: column">
<!--        <h2>Votre avis compte pour nous !</h2>-->
<!--        <div v-if="!surveyAnswered" style="margin-bottom: 1em">-->
<!--          <p>Seriez vous intéressé par la mise en place d'un service de livraison ?</p>-->
<!--          <v-progress-circular v-if="loading" color="white"></v-progress-circular>-->
<!--          <v-flex v-else style="justify-content: space-evenly">-->
<!--            <dpa-btn @click="deliverySurvey('oui')" text color="white">OUI</dpa-btn>-->
<!--            <dpa-btn @click="deliverySurvey('non')" text color="white">NON</dpa-btn>-->
<!--          </v-flex>-->
<!--        </div>-->
<!--        <div v-if="message" style="padding: 2rem 0">{{message}}</div> -->
        <!-- TrustBox widget - Micro Review Count -->
        <div class="trustpilot-widget" data-locale="fr-FR" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="61eac6c7b418e1a4325f2ced" data-style-height="24px" data-style-width="100%" data-theme="dark" data-min-review-count="10">
          <a href="https://fr.trustpilot.com/review/drivepiecesauto.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
      </div>
    </div>
    <footer>
      <div class="upper-footer">
        <div class="section">
          <div>
            <h3>A propos de Drive Pièces Auto</h3>
            <ul>
              <li><a href="mailto:contact@drivepiecesauto.com">Nous contacter</a></li>
              <li><router-link to="/cgv">Conditions générales de vente</router-link></li>
              <li><router-link to="/cgu">Conditions générales d'utilisation</router-link></li>
              <li><router-link to="/rgpd">Politique de protection des données personnelles</router-link></li>
              <li><router-link to="/mention-legale">Mentions légales</router-link></li>
            </ul>
          </div>

        </div>

        <div class="section">
          <h3>Mode de paiement 100% sécurisé</h3>
          <div class="payment-provider">
            <img class="payment-icon" :src="`${require('../../images/logos/visa_white.png')}`" alt="icône visa" />
            <img class="payment-icon" :src="`${require('../../images/logos/mastercard_white.png')}`" alt="icône mastercard" />
            <img class="payment-icon" :src="`${require('../../images/logos/cb_white.png')}`" alt="icône cb" />
            <img class="payment-icon" :src="`${require('../../images/logos/paypal_white.png')}`" alt="icône paypal" />
            <a href="https://getalma.eu/" target="_blank">
              <img class="payment-icon" style="height: 30px" :src="`${require('../../images/logos/alma_logo_white.png')}`" alt="Paiement en 3/4x avec Alma" />
            </a>
          </div>
          <h3>Nos partenaires</h3>
          <div class="logos">
            <a href="https://www.societegenerale.com/" target="_blank"><img style="height: 30px; width: auto" :src="`${require('../../images/logos/sg_white.png')}`" alt="Paiement sécurisée avec la Société Générale" /></a>
            <a href="https://www.infopro-digital-automotive.com/inovaxo/" target="_blank"><img style="height: 30px; width: auto" :src="`${require('../../images/logos/inovaxo-logo-white.png')}`" alt="Innovaxo - Infopro digital automotive" /></a>
            <a href="https://www.cicerone.fr" target="_blank"><img style="height: 30px; width: auto" :src="`${require('../../images/logos/cicerone.png')}`" alt="Powered by Cicerone" /></a>
            <a href="https://www.alternative-autoparts.fr/" target="_blank"><img style="height: 50px; width: auto" :src="`${require('../../images/logos/alternative-autoparts.png')}`" alt="Powered by Cicerone" /></a>
          </div>
        </div>
      </div>
      <div class="down-footer">
        Drive Pièces Auto 2023
      </div>
      <!--    <div>Icônes conçues par <a href="https://www.flaticon.com/fr/auteurs/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com</a></div>-->
      <!--    <a href="http://www.freepik.com">Designed by rawpixel.com / Freepik</a>-->
    </footer>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  //import DpaBtn from "@/components/Button";

  export default {
    //components: {DpaBtn},
    data() {
      return {
        message: null,
        loading: false
      }
    },
    computed: {
    ...mapState({
      surveyAnswered(state) {
        return state.session.surveyAnswered
      }
    })
    },
    methods: {

      deliverySurvey(response) {
        this.loading = true
        this.$store.dispatch("session/answerSurvey", {
          surveyId: 'delivery',
          response: response
        }).then(() => {
          this.message = "Merci pour votre réponse !"
          setTimeout(() => this.message = null, 15000)

        }).finally(() => {
          this.loading = false
        })

      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/colors";

  .reviews {
    display: flex;
    color: white;
    background-color: #27282C;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    min-height: 200px;

    .page {

      padding: 0;
    }

    .page-content {
      padding: 0;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 4em 3em 4em;
    //border-top: 1px solid $border-color;
    color: white;
    background-color: $background-color-gray-dark;

    .upper-footer {
      display: flex;
      justify-content: space-around;
      flex: 1;
      width: 100%;
      max-width: 1248px;
      margin: auto 0;

      .payment-provider {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .payment-icon {
          width: auto; height: 40px;
          margin-right: 1em;
        }
      }

      .section {
        flex: 1;
        padding-right: 6em;

        h3 {
          margin-bottom: 0.5em;
          margin-top: 1em;
        }

        img {
          display: block;
        }


        ul {
          margin: 0;
          padding: 0;
          li {
            list-style-type: none;
            margin: 0;
          }
        }

        .logos {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          a {
            margin-right: 1em;
            margin-bottom: 0.5em;
          }
        }
      }

      .section:last-child {
        padding-right: 0;
      }

    }

    .down-footer {
      width: 100%;
      max-width: 1248px;
      text-align: center;
      padding: 2em 0 0 0;
    }
  }

  @media only screen and (max-width: 1280px) {

    .reviews {

      h2 {
      }
    }
  }

  @media only screen and (max-width: 1000px) {

    footer {
      .upper-footer {
        .section {
          padding-right: 3em;
          flex: 1;
        }

        .section:last-child {
          flex: 2;
        }
      }

    }
  }

  @media only screen and (max-width: 700px) {

    .reviews {
      padding: 0 1em;
    }

    footer {
      padding: 1em;
      .upper-footer {
        flex-direction: column;
        .section {
          padding-right: 3em;
          margin-bottom: 1em;

          .logos {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-start;

            img {
              margin-bottom: 0.5em;
            }
          }
        }
      }

      .down-footer {
        text-align: center;
      }

    }
  }

  @media only screen and (max-width: 450px) {
    .reviews {
      h2 {
        font-size: larger;
      }
    }
  }
</style>
