<template>
  <Block class="product-wrapper" no-margin :no-shadow="flat" :no-border="flat">
    <div class="product">
      <div class="product-description-wrapper">
        <v-img :height="imgHeight" :width="imgWidth" v-bind:src="product.imgUrl"
               :alt="productName"></v-img>
        <div :class="productDescriptionClass">
          <div>
            <div class="name">{{ productName }}</div>
            <div class="price">{{ unitPriceTTCWithoutDeposit }} €</div>
            <div style="margin-bottom: 1em;" v-if="product.sellQuantity > 1"><em>soit {{unitPrice}} € l'unité</em></div>
            <ProductDeposit :value="product" />
            <QuantitySelector :quantity="quantity"
                              :loading="updateQtyLoading"
                              :product-ref="{
                                reference: product.reference,
                                supplierReference: product.supplierReference,
                                supplierId: product.supplierId,
                                supplier: !summarize ? product.supplier : null
                              }"
                              @on-quantity-changed="updateQuantity" :align-right="alignRight"/>
            <div v-if="error" class="error--text">{{error}}</div>
          </div>
          <div :class="priceBlockClass">
            <div>
              {{ itemTotalTTC }} €
            </div>
            <v-btn icon v-if="!noDelete"
                   @click="$store.dispatch('cart/removeProduct', {
                     supplier: product.supplier,
                     supplierId: product.supplierId,
                     reference: product.reference,
                     supplierReference: product.supplierReference})">
              <v-icon class="icon-link">mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

  </Block>
</template>

<script>
import QuantitySelector from '@/pages/cart/components/QuantitySelector'
import ProductDeposit from "@/components/product/ProductDeposit";
import Block from "@/components/SimpleBlock.vue";
import {round} from "lodash-es";
import {mapState} from "vuex";

export default  {
  components: {Block, ProductDeposit, QuantitySelector},
  props: {
    flat: Boolean,
    noDelete: Boolean,
    value: Object,
    alignRight: Boolean,
    summarize: Boolean
  },
  data() {
    return {
      item: this.value ? this.value : {},
      error: null,
      updateQtyLoading: false
    }
  },
  watch: {
    value(val) {
      this.item = val ? val : {}
    }
  },
  computed: {
    ...mapState({
      quantity(state) {
        return this.summarize ? state.cart.items
            .filter(i => i.product.supplierReference === this.item.product.supplierReference)
            .map(f => f.quantity)
            .reduce((a, b) => a + b) : this.item.quantity
      },
    }),
    productDescriptionClass() {
      let clazz = "product-description"
      if (this.alignRight) clazz += " align-right"
      return clazz
    },
    priceBlockClass() {
      let clazz = "price-block"
      if (this.alignRight) clazz += " align-right"
      return clazz
    },
    imgHeight() {
      return this.$isMobile() ? 125 : 150
    },
    imgWidth() {
      return this.$isMobile() ? 125 : 150
    },

    product() {
      return this.item.product ? this.item.product : {}
    },
    sellQuantity() {
      return this.product.sellQuantity
    },
    productName() {
      return this.$productName(this.product)
    },
    unitPrice() {
      return this.product.price.toFixed(2)
    },
    unitPriceTTCWithoutDeposit() {
      return this.$unitPriceTTCWithoutDeposit(this.product).toFixed(2)
    },
    itemTotalTTC() {
      let itemTotal = (this.product.priceHT * this.quantity * this.product.sellQuantity) * 1.2 + this.product.priceTTCDeposit * this.quantity;
      return round(itemTotal, 2).toFixed(2)
    },
  },
  methods: {
    async updateQuantity(args) {
      try {

        this.error = null
        this.updateQtyLoading = true
        await this.$store.dispatch("cart/updateProductQuantity", args)

      } catch (e) {
        this.error = `Désolé, il n'y a que ${this.quantity} exemplaire(s) disponible`
      } finally {
        this.updateQtyLoading = false
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../Cart.scss";

.product-wrapper {
  position: relative;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 300;
  padding: 10px 20px 10px 20px;
  background-color: $white;

  .product {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .product-description-wrapper {
      display: flex;
      width: 100%;

      .product-description {
        display: flex;
        flex-direction: row;
        padding-left: 1em;
        justify-content: space-between;
        width: 100%;

        .name {
          font-size: x-large;
          margin-bottom: 0.5em;
        }

        .price {
          font-size: large;
          margin-bottom: 0.5em;
        }

        .price-block {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          text-align: right;
          font-size: x-large;

          i {
            font-size: x-large;
            opacity: 0.8;
          }

        }

        .price-block.align-right {
          justify-content: flex-end;
        }
      }

      .product-description.align-right {
        text-align: end;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .product-wrapper {
    .product {
      width: auto;
      .product-description-wrapper {
        width: auto;
        .product-description {
          flex-direction: column;
          width: auto;

          img {
            height: 50px;
            width: auto;
          }

          .price-block {
            flex-direction: row;
            margin-top: 1em;
            align-items: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 374px) {
  .product-wrapper {
    padding: 1em 0.5em;
  }
}
</style>
