import Vue from "vue"
import VueRouter from "vue-router";

import HomePage from "@/pages/HomePage";

const Search = () => import(/* webpackChunkName: "group-search" */ '@/pages/SearchPage.vue')
const Product = () => import(/* webpackChunkName: "group-product" */ '@/pages/ProductPage.vue')

const Cart = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/CartPage.vue')
const CartItems = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/CartItems')
const Payment = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/PaymentPage.vue')
const SelectUserInfo = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/SelectUserInfo')
const OrderConfirmation = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/OrderConfirmation')
const PaymentConfirmation = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/PaymentConfirmation')
const Checkout = () => import(/* webpackChunkName: "group-cart" */ '@/pages/cart/CheckoutRedirection.vue')

const LoginPage = () => import(/* webpackChunkName: "group-account" */ '@/pages/LoginCheck')
const Account = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserAccount.vue')
const Orders = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserOrders.vue')
const Order = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserOrder.vue')
const OrderReturn = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/OrderReturn')
const ReturnConfirmation = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/ReturnConfirmation')
const Returns = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserReturns.vue')
const Vouchers = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserVouchers.vue')
const UserInfo = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserInfo')
const Vehicles = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/UserVehicles.vue')
const Logout = () => import(/* webpackChunkName: "group-account" */ '@/pages/account/AppLogout.vue')

const FAQ = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/FAQ')
const SAVProducts = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVProducts')
const SAVPrices = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVPrices')
const SAVPlaceOrder = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVPlaceOrder')
const SAVFollowOrder = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVFollowOrder')
const SAVReturns = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVReturns')
const SAVRefunds = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVRefunds')
const SAVWarranty = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVWarranty')
const SAVDeposit = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVDeposit')
const SAVPaymentProvider = () => import(/* webpackChunkName: "group-sav" */ '@/pages/sav/SAVPaymentProvider')

const CGU = () => import(/* webpackChunkName: "group-legal" */ '@/pages/CGU')
const CGV = () => import(/* webpackChunkName: "group-legal" */ '@/pages/CGV')
const RGPD = () => import(/* webpackChunkName: "group-legal" */ '@/pages/RGPD')
const LegalMention = () => import(/* webpackChunkName: "group-legal" */ '@/pages/LegalMention')
const PageNotFound = () => import(/* webpackChunkName: "group-other" */ '@/pages/PageNotFound')

Vue.use(VueRouter)

const routes = [
        { path: '/', component: HomePage},
        { path: '/sav', component: FAQ},
        { path: '/sav/catalogue', component: SAVProducts },
        { path: '/sav/les-prix', component: SAVPrices },
        { path: '/sav/passer-commande', component: SAVPlaceOrder },
        { path: '/sav/gerer-commandes', component: SAVFollowOrder },
        { path: '/sav/retours', component: SAVReturns },
        { path: '/sav/remboursements', component: SAVRefunds },
        { path: '/sav/garanties', component: SAVWarranty },
        { path: '/sav/consignes', component: SAVDeposit },
        { path: '/sav/paiement', component: SAVPaymentProvider },
        { path: '/panier', component: Cart, children: [
                { path: '', component: CartItems},
                { path: '/commande/paiement', component: Payment, meta: {
                        protected: true,
                        needCheckout: true,
                        needUserInfo: true
                    }
                },
                { path: '/commande/client/info', component: SelectUserInfo, meta: {
                        protected: true,
                        needCheckout: true
                    }
                },
                { path: '/commande/confirmation', component: OrderConfirmation, meta: {
                        protected: true,
                        needCheckout: true,
                        needPayment: true
                    }
                },
                { path: '/paiement/confirmation', component: PaymentConfirmation, meta: {
                        protected: true,
                        needCheckout: true
                    }
                },
            ]},
        { path: '/panier/validation', component: Checkout, meta: { protected: true}},
        { path: '/catalogue', component: Search},
        { path: '/catalogue/c/:subCategory', component: Search},
        { path: '/catalogue/c/:subCategory/:criterion', component: Search},
        { path: '/catalogue/v/:brandName/:groupName/:engine/c/:subCategory', component: Search},
        { path: '/catalogue/v/:brandName/:groupName/:engine/c/:subCategory/:criterion', component: Search},
        { path: '/catalogue/v/:brandName/:groupName/:engine', component: Search},
        { path: '/produits/:supplierId/:reference', component: Product},

        { path: '/login/check', component: LoginPage },
        { path: '/account', name: 'account', component: Account, meta: {protected: true, analyticsIgnore: true}, children: [
                { path: 'orders', component: Orders, children: [
                        { path: ':orderId', component: Order},
                        { path: ':orderId/return', component: OrderReturn },
                        { path: ':orderId/return/confirmation', component: ReturnConfirmation }
                    ]
                },
                { path: 'returns', component: Returns},
                { path: 'vouchers', component: Vouchers},
                { path: 'user', component: UserInfo },
                { path: 'vehicles', component: Vehicles},
            ] },
        { path: '/logout', component: Logout },
        { path: '/cgu', component: CGU },
        { path: '/cgv', component: CGV },
        { path: '/rgpd', component: RGPD },
        { path: '/mention-legale', component: LegalMention },
        { path: "*", component: PageNotFound }
    ]

let router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

const IDS_MAX_RETRY = 100
var IDS_RETRY_COUNT = 0

router.beforeEach(async (to, from, next) => {

    if (to.matched.some(record => record.meta.protected)) {

        while (!router.app.$keycloak?.ready  && IDS_RETRY_COUNT <= IDS_MAX_RETRY) {
            console.log("retry from router")
            IDS_RETRY_COUNT++
            await sleep(100)
        }

        let isAuthenticated =  router.app.$keycloak && router.app.$keycloak.authenticated;
        if (isAuthenticated) {

            let isCheckingOut = router.app.$store.getters["cart/isCheckingOut"];

            let needCheckout = to.matched.some(record => record.meta.needCheckout)
            let needPayment = to.matched.some(record => record.meta.needPayment)
            let needUserInfo = to.matched.some(record => record.meta.needUserInfo)

            if ((needCheckout || needUserInfo || needPayment) && !isCheckingOut) {
                await router.replace("/panier")

            } else {
                let hasBillingAddress = router.app.$store.getters["cart/hasBillingAddress"](new Date());
                if (needUserInfo && isCheckingOut && (!hasBillingAddress)) {
                    await router.replace("/commande/client/info")

                } else if (needPayment && isCheckingOut && (!router.app.$store.getters["cart/hadPaid"])) {
                    await router.replace("/commande/paiement")

                } else {
                    next()
                }
            }

        } else {


            let redirectUri = process.env.VUE_APP_HOST + to.fullPath
            let campaign = router.app.$store.getters["session/getCampaignUrl"]
            if (campaign) {
                redirectUri += campaign
            }
            const loginUrl = router.app.$keycloak.createLoginUrl({redirectUri: redirectUri})
            window.location.replace(loginUrl)
        }

    } else {
        next()
    }
})

export default router
