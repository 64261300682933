import { DayOfWeek } from 'shared-model'
export interface Hours {
    start: string
    end: string
}

export interface OpeningHours {
    day: DayOfWeek
    hours: Array<Hours>
    asString: string
}

export interface RelayPoint {
    id: string
    name: string
    address1: string
    address2?: string
    address3?: string
    zipcode: string
    countryCode: string
    city: string
    openingHours: Array<OpeningHours>
    url?: string
    distanceInMeter: number
}

export interface State {
    error?: string
    loading: Boolean
}

export interface DeliveriesState extends State {
    relayPoints: Array<RelayPoint>
    deliveryAddress?: Address
}

export enum DeliveriesMutation {
    SET_RELAY_POINTS = 'setRelayPoints',
    RESET_RELAY_POINTS = 'resetRelayPoints',
    SET_ERROR = 'setError',
    SET_LOADING = 'setLoading'
}

export interface Address {
    zipcode: string
    city: string
    street?: string
}
