import Vue from 'vue'
import Vuex, {createLogger} from 'vuex'
import VuexPersistence from 'vuex-persist'
import ls from 'localstorage-slim';
import session from './modules/session'

import cart from './modules/cart'
import catalog from './modules/catalog'
import vehicle from './modules/vehicle'
import orders from './modules/orders'
import deliveries from './modules/deliveries'
import shops from './modules/shops'
Vue.use(Vuex)

const debug = process.env.VUE_APP_ENV !== 'production'

const secureLocalStorage = {
    getItem: (key) => ls.get(key, {decrypt: !debug}),
    setItem: (key, value) => ls.set(key, value, {encrypt: !debug}),
    removeItem: (key) => ls.remove(key),
}

// FIXME TEMP
window.localStorage.removeItem("dpa-store")
window.localStorage.removeItem("dpa-store-secure")

// FIXME use session storage and reload cart when needed ?
const dataState = new VuexPersistence({
    key: "dpa-store-secure-2",
    paths: ['vehicle', 'cart', "catalog"],
    storage: debug ? localStorage : secureLocalStorage,
    reducer: state => {
        let vehicle = Object.assign({}, state.vehicle.vehicle)
        delete  vehicle.carKey
        return {
            cart: {
                cartId: state.cart.cartId,
                checkingOut: state.cart.checkingOut,
                pendingOrder: state.cart.pendingOrder
            },
            catalog: {
                brands: state.catalog.brands,
                categories: state.catalog.categories,
                flattenCategories: state.catalog.flattenCategories
            },
            vehicle: {
                vehicle: vehicle
            },
            session: {
                campaign: state.session.campaign,
                campaignTrackingId: state.session.campaignTrackingId,
                campaignTrackingIdType: state.session.campaignTrackingIdType,
                campaignTrackingIdExpiryIn: state.session.campaignTrackingIdExpiryIn,
                vehicles: state.session.vehicles,
                logoutInitiatedByUser: state.session.logoutInitiatedByUser,
                surveyAnswered: state.session.surveyAnswered
            }
        }
    },
})

const plugins = [dataState.plugin]
if (debug) plugins.push(createLogger())

export default new Vuex.Store({
    modules: {
        session,
        cart,
        catalog,
        vehicle,
        orders,
        deliveries,
        shops
    },
    strict: debug,
    plugins: plugins
})

