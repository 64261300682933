import { render, staticRenderFns } from "./ProductDeposit.vue?vue&type=template&id=57ade8b8&scoped=true"
import script from "./ProductDeposit.vue?vue&type=script&lang=js"
export * from "./ProductDeposit.vue?vue&type=script&lang=js"
import style0 from "./ProductDeposit.vue?vue&type=style&index=0&id=57ade8b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_qty7iymlffgpukvncpluvycwfu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ade8b8",
  null
  
)

export default component.exports