<template>
  <v-overlay :value="showOverlay" :opacity="0.6" :z-index="zIndex" :absolute="absolute"  >
    <slot />
  </v-overlay>
</template>

<script>
export default {

  props: {
    active: Boolean,
    zIndex: String,
    absolute: Boolean
  },
  data() {
    return {
      showOverlay: this.active
    }
  },
  watch: {
    active(value) {
      this.showOverlay = value
    }
  }
}
</script>

<style scoped>

</style>