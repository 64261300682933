import { OrderApi } from "@/api"
import download from "downloadjs";
import Vue from 'vue'

const state = () => ({
    orders: [],
    error: null,
    loading: false
})

const getters = {

    getOrders: state => {
        return state.orders
    },

    getOrderById: state => (id) => {
        return state.orders.find(o => o.orderId === id)
    }
}

const actions = {

    getOrders({commit}) {

        commit("setLoading", true)
        return OrderApi.loadOrders()
            .then(response => {
                commit("setOrders", response)
                return response
            }).catch(() => {
                commit("setError", "Erreur de chargement des commandes")
            }).finally(() => commit("setLoading", false))
    },

    async getInvoice({commit}, orderId) {

        try {
            commit("setLoading", true)
            let response = await OrderApi.getInvoice(orderId)
            const content = response.headers['content-type'];
            let invoices = response.data
            let filenames = Object.keys(invoices);
            filenames.forEach((invoice, i) => {

                const file = dataURLtoFile(`data:application/pdf;base64,${invoices[invoice]}`, invoice);
                let filename = filenames.length === 1
                    ? `drive-pieces-auto_facture_#${Vue.prototype.$formatOrderId(orderId)}.pdf`
                    : `drive-pieces-auto_facture_#${Vue.prototype.$formatOrderId(orderId)}_${i + 1}.pdf`
                download(file, filename, content)
            })


        } catch (e) {
            throw "Erreur technique. Veuillez réessayer dans quelques instants."

        } finally {
            commit("setLoading", false)
        }
    }
}

function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}


const mutations = {

    setOrders(state, orders) {
        state.orders = orders
    },

    setError(state, error) {
        state.error = error
    },

    setLoading(state, status) {
        if (status) state.error = null
        state.status = status
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
