import Vue from 'vue'
import dayjs from "dayjs";

export default {

    install(Vue) {

        Vue.prototype.$formatDate = function(date, format) {
            let cleanedDate = clean(date);
            return cleanedDate ? cleanedDate.format(format) : ''
        }

        Vue.prototype.$cleanDate = clean

        Vue.prototype.$computeAvailabilityDisplay = function (date) {

            if (date == null) return

            let availabilityDate = dayjs(date)
            let nowMonth = dayjs().get("month")
            let month = availabilityDate.get("month")

            let dayDifference = nowMonth === month ? dayjs().get("date") - availabilityDate.get("date") : 99

            switch (dayDifference) {
                case 0:
                    return `aujourd'hui ${computeTimeAvailability(availabilityDate)}`
                case 1:
                    return `demain ${computeTimeAvailability(availabilityDate)}`
                default:
                    return `le ${availabilityDate.format("D MMMM")} ${computeTimeAvailability(availabilityDate)}`
            }
        }

        Vue.prototype.$formatOrderId = function (orderId) {
            return orderId ? orderId.substring(0, 8).toUpperCase() : null
        }

        Vue.prototype.$isAllowToPay = function (isAdmin) {
            return process.env.VUE_APP_ALLOW_PAYMENT === 'true' || isAdmin
        }

    }
}

function computeTimeAvailability(date) {

    let now = dayjs()
    let nowHour = now.get('hour')
    let nowMinute = now.get('minute')
    let isSameDay = date.get("day") === now.get('day')
    let availableAm = date.get("hour") < 12

    let openAm = nowHour >= 8 && (nowHour < 12)
    let openPM = (nowHour === 13 && nowMinute >= 30) || (nowHour > 13 && nowHour < 18)

    const availabilityHour = `${Vue.prototype.$formatDate(date, 'HH[h]mm').replace("00", "")}`;

    if (nowHour < 8 )
        return isSameDay ? availableAm ? "dès 8h" : `dès ${availabilityHour}` :  `dès ${availabilityHour}`

    else if (openAm)
        return isSameDay ? availableAm ? "avant 12h" : `dès ${availabilityHour}` :  `dès ${availabilityHour}`

    else if (nowHour >= 12 && (nowHour < 13 || (nowHour < 14 && nowMinute <= 30)))
        return isSameDay ? "dès 13h30" :  `dès ${availabilityHour}`

    else if (openPM) return isSameDay ? "avant 18h" : `dès ${availabilityHour}`

    else return `dès ${availabilityHour}`
}

function clean(date) {
    if ( date ) {
        return typeof date === 'string' || date instanceof String
            ? dayjs(date.replace(/UTC/g, "Z"))
            : date
    } else return null
}
