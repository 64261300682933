import Vue from "vue";
import {isEmpty, groupBy, reduce} from "lodash-es";
import {CartApi, PaymentApi, VouchersApi} from "@/api"

const getDefaultState = () => {
    return {
        items: [],
        checkingOut: false,
        hasPaid: false,
        pendingOrder: {},
        voucher: null,
        loading: false,
        error: null,
        cartId: null,
        totalTTC: null,
        totalHT: null,
        totalHTConsigne: null,
        totalHTAfterDiscount: null,
        totalWalletUsed: null,
        totalTVA: null,
        delivery: null,
        deliveryType: null,
        totalDiscount: null,
        expirationDate: null,
        orderSignature: null,
        transactionDate: null,
        transactionId: null,
        refreshError: false,
        deliveryOptions: []
    };
}

const state = getDefaultState()

const getters = {

    getOrderWeight: state => {
        return reduce(state.items.map(i => {
            return parseFloat(i.product.weight) * i.quantity
        }), (a, b) => a + b)
    },
    getOrderAvailabilityDate: state => {
        return new Date(Math.max.apply(null,state.items.map(i => new Date(i.product.availabilityDate))));
    },
    isCheckingOut: state => {
        return state.checkingOut
    },
    hadPaid: state => {
        return state.hasPaid
    },
    // date arg is a hack to disable cache
    hasBillingAddress: state => date => {
        return date && !isEmpty(state.pendingOrder) && state.pendingOrder.billingAddress != null
    },
    getCartItems: state => {
        return state.items
    },
    getCartItemNumber: state => {
        return state.items.length
    },
    getItemsByAvailability: (state, getters) => {
        return groupBy(getters.getCartItems, (item) => {
                return item.product.availabilityDate
                    ? item.product.availabilityDate
                    : -1
            }
        )
    },
    getNumberOfItemsAsString: (state, getters) => {
        let nbProducts = getters.getCartItems.reduce((a, b) => {
            return {quantity: parseInt(a.quantity) + parseInt(b.quantity)}
        }, {quantity: 0});
        let products = nbProducts.quantity === 1 ? "produit" : "produits"
        return `(${nbProducts.quantity} ${products})`
    },
    getCartItemsBySupplierReference: (state) => (ref) => {
        return state.items.filter(i => i.product.supplierReference === ref)
    },
    getCartDiscountValue: (state) => {
        if (!state.voucher) {
            return null
        } else if (state.voucher.valid && state.voucher.discountType === 'PERCENT') {
            return `${state.voucher.discount} %`
        } else if (state.voucher.valid && state.voucher.discountType === 'AMOUNT') {
            return `${state.voucher.discount.toFixed(2)} €`
        }
    },
    getTotalWalletUsedText: (state) => {
        if (!state.voucher) {
            return null
        } else if (state.voucher.valid && state.voucher.discountType === 'WALLET') {
            return `${state.totalWalletUsed.toFixed(2)} €`
        } else return null
    },
    getCartDiscountText: (state) => {
        if (!state.voucher) {
            return null
        } else if (state.voucher.valid && (state.voucher.discountType === 'PERCENT' || state.voucher.discountType === 'AMOUNT')) {
            return "Remises"
        } else return "Compte virtuel"
    },
    getPendingOrderNumber: (state) => {
        return state.pendingOrder && state.pendingOrder.orderId
            ? state.pendingOrder.orderId.substring(0, 8).toUpperCase()
            : null
    },
    getPendingOrder: (state) => {
        return state.pendingOrder
    },
    getCartId: (state) => {
        return state.cartId
    }
}


const actions = {

    async get({commit, state}) {

        try {
            if (state.hasPaid) return;

            let result = await CartApi.get(state.cartId)
            commit("setCart", result)
            commit("setDelivery", {
                deliveryType: result?.deliveryType,
                delivery: result?.delivery
            })
            commit("setTotals", result?.totals)

        } catch (e) {
            switch (e) {
                case 401:
                    break;
                case 403:
                    throw "Ce panier ne vous appartient pas"
                case 404:
                    throw "Le panier a expiré"
                default:
                    throw "Erreur technique, veuillez réessayer"
            }
        }

    },

    async refresh({commit, state}) {

        try {
            if (!state.cartId) return;

            let result = await CartApi.refresh(state.cartId)
            commit("setRefreshError", result == null)

            if (result != null) {
                commit("setCartDifferences", result)
            }

            commit("setCart", result)
            commit("setDelivery", {
                deliveryType: result?.deliveryType,
                delivery: result?.delivery
            })
            commit("setTotals", result?.totals)

            return result

        } catch (e) {
            switch (e) {
                case 403:
                    throw "Ce panier ne vous appartient pas"
                case 404:
                    throw "La panier a expiré"
                default:
                    throw "Erreur technique, veuillez réessayer"
            }
        }

    },

    async delete({commit, state}) {

        try {
            if (!state.cartId) return;

            await CartApi.delete(state.cartId)
            commit("setCart", null)

        } catch (e) {
            switch (e) {
                case 403:
                    throw "Ce panier ne vous appartient pas"
                case 404:
                    throw "La panier n'existe pas"
                default:
                    throw "Erreur technique, veuillez réessayer"
            }
        }

    },

    async addItem({commit, state, rootState}, {product, noCarId}) {

        let vehicle = rootState.vehicle.vehicle
        let mineType = vehicle?.pastMineType
        let platenumber = vehicle?.platenumber
        let modelId = vehicle?.ciceroneCode
        let carCodes = !noCarId ? {
            ciceroneCode: modelId,
            mineType: mineType !== "" ? mineType : null,
            platenumber: platenumber
        } : null

        let result = await CartApi.addItem(product.reference, product.supplierReference, product.supplierId, carCodes, state.cartId)

        if (result.error) {
            if (result.errorCode === 'NOT_AVAILABLE') throw "Produit non disponible pour cette quantité"

        } else {
            commit("setCart", result)
            commit("setTotals", result.totals)
            let item = result?.items.find(i => i.product.supplierReference === product.supplierReference)
            if (item) {
                Vue.prototype.$matomo.addEcommerceItem(
                    item.product.supplierReference,
                    item.product.productName,
                    null, // (Optional) productCategory
                    item.product.price * product.sellQuantity,
                );
                Vue.prototype.$matomo.trackEcommerceCartUpdate(result.totals.totalTTC);
            }
        }

    },

    async updateProductQuantity({commit, state}, payload) {
        // TODO check if there is available quantity
        try {

            let result = await CartApi.updateProductQuantity(state.cartId, payload)
            if (result.error) {
                if (result.errorCode === 'NOT_AVAILABLE') throw "Produit non disponible pour cette quantité"

            } else {
                commit("setCart", result)
                commit("setTotals", result.totals)

                let product = result.items.find(i => i.product.supplierReference === payload.supplierReference)
                if (product) {
                    Vue.prototype.$matomo.addEcommerceItem(
                        product.supplierReference, // (Required) productSKU
                        product.productName, // (Optional) productName
                        null, // (Optional) productCategory
                        product.price * product.sellQuantity, // (Recommended) price
                        payload.quantity // (Optional, defaults to 1) quantity
                    );

                    Vue.prototype.$matomo.trackEcommerceCartUpdate(result.totals.totalTTC);
                }
            }



        } catch (e) {
            if (e === 404) throw "Erreur technique, veuillez réessayer"
            else throw e
        }
    },

    async removeProduct({commit, state}, payload) {
        // TODO check if there is available quantit

        let result = await CartApi.removeProduct(payload, state.cartId)
        commit("setCart", result)
        commit("setTotals", result?.totals)

        if (result) {
            let product = result.items.find(i => i.product.supplierReference === payload.supplierReference)
            if (product) {
                Vue.prototype.$matomo.addEcommerceItem(
                    product.supplierReference, // (Required) productSKU
                    product.productName, // (Optional) productName
                    null, // (Optional) productCategory
                    product.price * product.sellQuantity, // (Recommended) price
                    1 // (Optional, defaults to 1) quantity
                );

                Vue.prototype.$matomo.trackEcommerceCartUpdate(result.totals.totalTTC);
            }
        }
    },

    validatePromotionCode({commit, state}, voucherCode) {

        return VouchersApi.validateVoucher(voucherCode, state.cartId).then(cart => {

            commit("setCart", cart)
            commit("setTotals", cart.totals)
            let voucher = cart.voucher
            if (voucher && voucher.valid) {
                commit("addVoucher", voucher)
            } else if (voucher) {
                throw voucher.message
            }
        }).catch(e => {
            throw mapCartError(e)
        })
    },

    async checkout({commit, state}, userId) {

        commit("setLoading", true)
        commit("isCheckingOut", true)

        try {
            let response = await CartApi.checkout(state.cartId, userId)
            commit("setCart", response)
            commit("setTotals", response?.totals)
            return response

        } catch (e) {
            throw "Impossible de créer le bon de commande"
        } finally {
            commit("setLoading", false)
        }
    },

    async selectDelivery({commit, state}, payload) {

        let response = null
        commit("setLoading", true)
        try {
            response = await CartApi.updateDelivery(state.cartId, payload)
            commit("setCart", response)
            commit("setDelivery", {
                deliveryType: response?.deliveryType,
                delivery: response?.delivery
            })
            commit("setTotals", response?.totals)
        } catch (e) {
            throw "Erreur technique, veuillez réessayer !"
        } finally {
            commit("setLoading", false)
            Vue.prototype.$matomo.trackEvent("ECommerce", "Mode de livraison", response?.deliveryType, response?.deliveryType)
        }
    },

    async getDeliveriesOptions({commit, state}) {

        commit("setLoading", true)
        try {

            let response = await CartApi.getDeliveriesOptions(state.cartId)
            commit("setDeliveryOptions", response)
            return response

        } catch (e) {
            throw "Erreur technique, veuillez réessayer !"
        } finally {
            commit("setLoading", false)
        }
    },

    async saveUserInfo({commit, state}, userInfo) {

        commit("setLoading", true)
        try {

            let response = await CartApi.updateUserInfo(state.cartId, userInfo)
            commit("setCart", response)
            commit("setTotals", response?.totals)
            commit("setBillingAddress", response?.billingAddress)
            commit("setPhone", response?.phone)
            commit("setDelivery", {
                deliveryType: response?.deliveryType,
                delivery: response?.delivery
            })

        } catch (e) {
            throw "Erreur technique, veuillez réessayer !"
        } finally {
            commit("setLoading", false)
        }
    },

    async checkPaymentEligibility({commit, state}, provider) {

        if (state.loading) return;

        try {
            commit("setLoading", true)
            return await PaymentApi.checkPaymentEligibility(state.cartId, provider)

        } catch (e) {
            throw "Impossible de vérifier l'éligibilité"
        } finally {
            commit("setLoading", false)
        }
    },

    async initiatePayment({commit, state, rootGetters}, paymentType) {
        try {


            let campaign = rootGetters["session/getCampaign"]
            let trackingId = rootGetters["session/getCampaignTracking"]

            let paymentProvider = "PAYPAL"
            let installmentCounts = 1
            switch (paymentType) {
                case "CB":
                case "MASTERCARD":
                case "VISA":
                    paymentProvider = "SOGECOM"
                    installmentCounts = 1
                    break;
                case 'ALMA3x':
                    paymentProvider = "ALMA"
                    installmentCounts = 3
                    break;
                case 'ALMA4x':
                    paymentProvider = "ALMA"
                    installmentCounts = 4
                    break;
                case 'WALLET':
                    paymentProvider = "WALLET"
                    break;
            }

            const payload = Object.assign({
                provider: paymentProvider,
                paymentType: paymentType,
                installmentCounts: installmentCounts
            }, !isEmpty(campaign) ? {
                campaign: campaign.name,
                campaignKey: campaign.key
            } : {}, !isEmpty(trackingId) ? {
                campaignTrackingId: trackingId.campaignTrackingId,
                campaignTrackingIdType: trackingId.campaignTrackingIdType
            } : {});

            let response = await PaymentApi.initiatePayment(state.cartId, payload)

            commit("setInitiatePaymentResponse", {response, provider: paymentProvider})
            return response

        } catch (e) {
            throw "Impossible de créer le formulaire de paiement"
        }
    },

    async validatePayment({commit, state}, result) {
        try {
            commit("setLoading", true)
            let response = await PaymentApi.validatePayment(state.cartId, result);
            commit("setPendingOrder", response)
            return response

        } finally {
            commit("hasPaid", true)
            commit("setLoading", false)
        }
    }
}

function mapCartError(error) {

    if (typeof error === 'string' || error instanceof String) {
        return error
    }

    switch (error) {
        case 404:
            return "Code invalide"
        default:
            return 'Erreur technique, veuillez réessayer'

    }
}


const mutations = {

    setOrderSignature(state, signature) {
        state.orderSignature = signature
    },
    setTransactionDate(state, date) {
        state.transactionDate = date
    },
    setCart(state, cart) {

        if (cart == null || isEmpty(cart)) {
            Object.assign(state, getDefaultState())

        } else {
            state.cartId = cart.id
            state.voucher = cart.voucher
            state.items = cart.items ?? []
            state.expirationDate = cart.expirationDate + ""
            state.isCheckingOut = !!cart.userId
        }
    },

    setCartDifferences(state, newCart) {
        let oldItems = state.items;
        // FIXME handle voucher expiration let oldVoucher = state.voucher;

        let changedItems = newCart.items.filter(i => {
            return i.product.price !== oldItems.find(o => o.supplierReference === i.supplierReference)?.price
        })

        state.cartDifferences = Object.assign({}, {
            price: changedItems.map(i => i.supplierReference)
        })
    },

    setTotals(state, totals) {

        if (totals) {
            state.totalHT = totals.totalHT
            state.totalHTConsigne = totals.totalHTConsigne
            state.totalHTAfterDiscount = totals.totalHTAfterDiscount
            state.totalDiscount = totals.totalDiscount
            state.totalTTC = totals.totalTTC
            state.totalTVA = totals.totalTVA
            state.deliveryFee = totals.deliveryFee
            state.totalWalletUsed = totals.totalWalletUsed
        }
    },

    resetCart(state) {
        Object.assign(state, getDefaultState())
    },

    setExpirationDate(state, expirationDate) {
        state.expirationDate = expirationDate + ""
    },

    setRefreshError(state, value) {
      this.refreshError = value
    },

    addVoucher(state, voucher) {
        state.voucher = voucher
    },

    empty(state) {
        state.cartId = null
        state.items = []
        state.voucher = null
    },

    setError(state, error) {
        state.error = error
    },

    setLoading(state, status) {
        if (status) state.error = null
        state.loading = status
    },

    setInitiatePaymentResponse(state, {response, provider}) {
        if (provider === 'SOGECOM') {
            state.orderSignature = response.signature
            state.transactionDate = response.transactionDate
            state.transactionId = response.transactionId
        } else if (provider === 'ALMA') {
            state.transactionId = response.transactionId
        }
    },

    isCheckingOut(state, val) {
        state.checkingOut = val
    },

    hasPaid(state, val) {
        state.hasPaid = val
    },

    setPendingOrder(state, order) {
        if (order !== "") {
            state.pendingOrder = order
        }
    },

    updatePendingOrder(state, updatzeFielf) {
        state.pendingOrder = Object.assign(this.state.pendingOrder, updatzeFielf)
    },

    setDelivery(state, {delivery, deliveryType}) {
        if (isEmpty(state.pendingOrder)) {
            state.pendingOrder = Object.assign({}, {
                deliveryType,
                delivery
            })
        } else {
            state.pendingOrder.deliveryType = deliveryType
            state.pendingOrder.delivery = delivery
        }
    },

    setDeliveryOptions(state, options) {
        state.deliveryOptions = options
    },

    setBillingAddress(state, billingAddress) {
        if (isEmpty(state.pendingOrder)) {
            state.pendingOrder = Object.assign({}, {
                billingAddress: billingAddress
            })
        } else {
            state.pendingOrder.billingAddress = billingAddress
        }
    },

    setPhone(state, phone) {
        if (isEmpty(state.pendingOrder)) {
            state.pendingOrder = Object.assign({}, {
                phone: phone
            })
        } else {
            state.pendingOrder.phone = phone
        }
    }

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
