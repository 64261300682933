<template>
  <div class="page in-column">
    <div  class="home-header bottom" v-bind:style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
      <h1 class="home-header-title">{{pageTitle}}</h1>
    </div>

    <div class="home-page-top-actions">
      <div class="reassurance-header">
        <div class="reassurance-header-item" @click="searchProducts">
          <div class="img-wrapper">
            <img :src="`${require('@/assets/fast_availability_2.webp')}`" alt="Haute disponibilité">
          </div>
          <span>Disponibilité à la <strong>demi-journée</strong></span>
        </div>
        <div class="reassurance-header-item" @click="$router.push('/sav/retours')">
          <div class="img-wrapper">
            <img :src="`${require('@/assets/easy_returns.webp')}`" alt="Bonne affaires"/>
          </div>
          <span>Retours <strong>simplifiés</strong> / Remboursement <strong>immédiat</strong></span>
        </div>
        <div class="reassurance-header-item" @click="$router.push('/sav/paiement')">
          <div class="img-wrapper">
            <img :src="`${require('@/assets/secure_payment.webp')}`" alt="Paiement 4 fois">
          </div>
          <span>Paiement en <strong>3 ou 4 fois</strong></span>
        </div>
      </div>

      <section>
        <VehicleSelectionTool :vehicle="vehicle" switchable dark />
      </section>

      <div v-if="$isMobile()" class="search-field">
        <SearchBar
            placeholder="Recherche par référence"
            @on-open="isSearchOpen = !isSearchOpen"
            @on-close="displaySearchScreen = false; isSearchOpen = false"/>
      </div>
    </div>

    <div>
      <div class="background-light">
        <div class="page-content text-centered reading-content">
          <h2 style="margin-top: 0; padding-top: 1rem">Vente de pièces auto pas cher, garanties neuves et d'origine</h2>
          <p class="main-text">Spécialisé dans la pièce automobile depuis plus de 20 ans, Drive Pièces Auto met à votre disposition près de 240 000 références à prix discount, disponibles immédiatement en magasin (selon stock disponible) ou à partir de la demi-journée. Payables jusqu’à 4 fois avec retours simplifiés.</p>
          <h3>Nous vous proposons une sélection des meilleures marques de pièces automobiles</h3>
          <div class="brand-wrapper">
            <div class="brand-item" v-for="brand in brands" v-bind:key="`brand-${brand.name}`">
              <img v-bind:src="brand.img" :alt="brand.name" :style="`height: ${brand.height}`"/>
            </div>
          </div>
        </div>
      </div>

      <section class="background-dark page-content">
          <h2 style="text-align: center; margin-top: 0; padding-top: 1rem">Explorer un large choix de pièces détachés des plus grands équipementiers</h2>
          <div class="category-wrapper">
            <div class="category-item" v-for="category in categories" v-bind:key="`cat-${category.name}`">
              <v-flex align-center>
                <img :src="`/${category.img}`" :srcset="categoryImgSet(category)" :alt="`pièce de ${category.name}`"/>
              </v-flex>
              <div class="category-info">
                <h3>{{category.name}}</h3>
                <span v-for="(subCat, i) in category.subCategories" v-bind:key="`subcat-${subCat.name}`">
                <router-link :to="computeCategoryUrl(subCat)">{{subCat.name}}</router-link>
                <span v-if="i < category.subCategories.length - 1 "> - </span>
              </span>
              </div>
            </div>
          </div>
        </section>
    </div>

  </div>
</template>

<script>

import VehicleSelectionTool from "@/components/vehicle/VehicleSelectionTool";
import SearchBar from "@/components/SearchBar";
import {mapState} from "vuex";
import {isEmpty} from "lodash-es";

export default {
    components: {VehicleSelectionTool, SearchBar},
    data() {
      return {
        //vehicle: null,
        search: null,
        isSearchOpen: false,
        displaySearchScreen: false,
        brands: [{
          name: "bosch",
          img: require("@/assets/logos/logo-bosch.webp")
        },
          {
            name: "dayco",
            img: require("@/assets/logos/logo-dayco.webp")
          },
          {
            name: "denso",
            img: require("@/assets/logos/logo-denso.webp")
          },
          {
            name: "luk",
            img: require("@/assets/logos/logo-luk.webp"),
            height: "50px"
          },
          {
            name: "monroe",
            img: require("@/assets/logos/logo-monroe.webp")
          },{
            name: "valeo",
            img: require("@/assets/logos/logo-valeo1.webp")
          },{
            name: "delphi",
            img: require("@/assets/logos/logodelphi.webp")
          },{
            name: "magentimarelli",
            img: require("@/assets/logos/logomagentimarelli.webp"),
            height: "70px"
          }]
      }
    },
    computed: {
      ...mapState({
        vehicle(state) {
          return state.vehicle.vehicle
        }
      }),
      innerWidth() {
        return window.innerWidth
      },
      backgroundImage() {

        if (this.innerWidth <= 420) return require('@/assets/mpa-main-background-420.webp')
        else if (this.innerWidth <= 640) return require('@/assets/mpa-main-background-640.webp')
        else if (this.innerWidth <= 920) return require('@/assets/mpa-main-background-920.webp')
        else return require('@/assets/mpa-main-background-1980.webp')
      },

      pageTitle() {
        return window.innerWidth < 1500
            ? "Pièces neuves et d'origine à prix discount"
            : "Drive Pièces Auto propose des pièces détachées pour votre voiture à prix discount"
      },
      categories() {
        return this.$store.getters["catalog/getCategories"]
      }
    },
    methods: {
      searchProducts() {
        let url = "/catalogue"
        if (this.vehicle && !isEmpty(this.vehicle)) {
          url += `/v${this.vehicle.url}`
        }
        this.$router.push(url)
      },
      computeCategoryUrl(item) {

        let url = "/catalogue"
        if (this.vehicle && !isEmpty(this.vehicle)) {
          url += `/v${this.vehicle.url}`
        }
        url += `/c/${this.$store.getters['catalog/getCategoryNameAsURI'](item.name)}`
        return url
      },
      categoryImgSet(category) {
        let filename = category.img.substring(0, category.img.lastIndexOf("."))
        return `/categories/3x/3x_${filename}.webp`
      }
    },
    async beforeMount() {
      await this.$store.dispatch("catalog/loadCategories")
    }
  }
</script>

<style lang="scss" scoped>

@import "src/styles/colors.scss";

h1, h2, h3 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 450px;
  border-bottom: 1px solid $gray;

  .home-header-title {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1.5em;
    max-width: 34%;
  }

}

.home-page-top-actions {
  display: flex;
  flex-direction: column;

  .advertise {
    font-size: 24px;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0 0.5em;
  }
}

.reassurance-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1em;

  .reassurance-header-item {
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    .img-wrapper {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 1em;
        height: 45px;
        width: auto;
      }
    }

    span {
      font-size: large;
    }
  }
}

.separator {
  background-color: $background-color;
  margin: 1em 0 2em 0;
}

.main-text {
  font-size: larger;
  letter-spacing: 1px;
}

.brand-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .brand-item {
    display: flex;
    align-items: center;
    img {
      margin-right: 0.5em;
      height: 100px;
      width: auto;
    }
  }
}

.category-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;

  .category-item {
    display: flex;
    flex: 1;
    padding: 1em;
    border: 1px solid $light-gray;
    min-width: 45%;
    background-color: #FFFFFF;
    margin: 1em;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; //https://getcssscan.com/css-box-shadow-examples

    img {
      margin-right: 0.5em;
      height: 150px;
      width: auto;
    }

    .category-info {
      padding: 2em 0 2em 2em;
      width: 100%;
    }
  }
}

.head {
  height: 330px;
  .bg {
    background-image: linear-gradient(-60deg, $background-color-gray-dark 50%, $background-color 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
  }
}

@media only screen and (max-width: 1500px) {

  .home-header {
    height: 356px;

  }

}


@media only screen and (max-width: 1000px) {

  .home-header {
    height: 230px;

    .home-header-title {
      font-size: 28px;
      max-width: 40%;
      margin: 0.5em 0;
      padding: 0 0.5em 0 0.5em
    }
  }

  .home-page-top-actions {

    flex-direction: column-reverse;
  }

  .reassurance-header {
    justify-content: space-between;
    padding: 0.5em;

    .reassurance-header-item {
      flex-direction: column;
      .img-wrapper {
        img {
          margin: 0;
        }
      }

      span {
        font-size: medium;
      }

    }
  }

  .main-text {
    font-size: large;
  }

  .brand-wrapper {
    display: flex;

    .brand-item {
      img {
        height: 100px;
        width: auto;
      }
    }
  }

  .category-wrapper {
    margin: 0;
    .category-item {
      margin: 0.5em;
      min-width: 95%;
      img {
        height: 100px;
      }

      .category-info {
        padding: 0 0 0 2em;
      }
    }
  }

}

@media only screen and (max-width: 700px) {
  .home-header {

    .home-header-title {
      max-width: 100%;
    }
  }

  .home-page-top-actions {
    .advertise {
      font-size: 18px;
    }
  }

  .category-wrapper {
    margin: 0;
    .category-item {
      margin: 0 0 0.75em 0;
      padding: 0.5em;
      min-width: 100%;
      img {
        padding-left: 0.5em;
        height: 100px;
      }

      .category-info {
        font-size: medium;
        padding: 0.5em 0 0.5em 1.5em;
      }
    }
  }

}

</style>

