'use strict'

export interface State {
    error?: string
    loading: boolean
}

export enum PaymentStatus {
    NOT_PAID = 'NOT_PAID',
    ERROR = 'ERROR',
    COMPLETED = 'COMPLETED',
    REFUSED = 'REFUSED',
    SUSPENDED = 'SUSPENDED',
    REFUNDED = 'REFUNDED',
    CANCELLED = 'CANCELLED',
}

export enum PaymentProvider {
    SOGECOM = "SOGECOM",
    PAYPAL = "PAYPAL",
    ALMA = "ALMA",
    WALLET = "WALLET"
}

export interface PaymentInfo {
    transactionId: string
    status: PaymentStatus
    provider: PaymentProvider
    totalTTC: number
    totalHT: number
    totalHTAfterDiscount: number
    totalTVA: number
    totalDiscount: number
    totalWalletUsed: number
    totalRefunded: number
}

export enum OrderStatus {
    CREATED = 'CREATED',
    PAID = 'PAID',
    PLACED = 'PLACED',
    ORDERED = "ORDERED",
    TO_PREPARE = 'TO_PREPARE',
    TO_DISPATCH = 'TO_DISPATCH',
    GIVEN_TO_CARRIER = 'GIVEN_TO_CARRIER',
    AVAILABLE = 'AVAILABLE',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR'
}

export interface OrderItem {
    quantity: number
    product: CartProduct
}

export interface CartProduct {
    reference: string
    supplierReference: string
    supplierId: number
    productName: string
    price: number
    priceHT: number
    priceHTConsigne: number
    priceTTCDeposit: number
    publicPrice: number
    sellQuantity: number
    weight: number
    imgUrl: string
    carIdentification?: CarIdentification
    availabilityDate?: string
    supplier: Supplier
}

export interface CarIdentification {
    ciceroneCode?: string
    mineType?: string
    platenumber?: string
}

export interface Supplier {
    code: string
    cutOffs: CutOff[]
    deliveryDelay: number
    priority: number
}
export interface CutOff {
    orderTime: string
    deliveryTime: string
}
export enum DeliveryType {
    DRIVE = 'DRIVE',
    CHRONOPOST_RELAY = 'CHRONOPOST_RELAY'
}

export interface Delivery {
    // FIXME todo
}

export interface Address {
    //FIXME todo
}

export interface Order {
    orderId: string
    userId: string
    status: OrderStatus
    creationDate: string
    updateDate: string | null
    deliveryDate: string | null
    paymentInfo: PaymentInfo | null
    externalId: string | null // Win Pro order id
    externalInvoiceId: Array<string> // Win Pro invoice id
    billingAddress: Address | null
    deliveryAddress: Address | null
    phone: string | null
    parcelTrackingKey: string | null
    items: Array<OrderItem>
    message: string | null
    usedVoucher: string | null
    returnId: string | null
    campaignTrackingId: string | null
    deliveryType: DeliveryType
    delivery: Delivery | null
}

export type { Shop, ShopAddress, Hours, OpeningHours, ClosingDays } from './shop-types'
export type { Voucher, VoucherStatus, ThresholdType, DiscountType } from './voucher-types'
export type { ReturnReason, ReturnItemStatus, ArticleReturnsStatus } from './returns-type'
export { DayOfWeek  } from './common-types'