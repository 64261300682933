<template>
  <UnderMaintenance v-if="underMaintenance"/>
  <v-app v-else id="app">
    <v-navigation-drawer  style="z-index: 102" disable-resize-watcher color="#4A4949" class="side-nav" fixed v-model="showSideNav" app width="285">
      <NavContent side-nav @on-menu-hit="toggleSideNav"/>
    </v-navigation-drawer>
    <Overlay :active="isAppLoading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </Overlay>
    <AppBar @on-menu-hit="toggleSideNav" />
    <v-main class="content">
      <router-view :key="$route.fullPath" />
    </v-main>
    <Footer />

    <Dialog title="Vous n'êtes plus connecté !" :dialog="sessionExpired" persistent>
      <template v-slot:content>

      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn text @click="backToHome">Retour à la page d'accueil</v-btn>
        <v-btn color="primary" @click="reconnectUser">Me connecter</v-btn>
      </template>
    </Dialog>

  </v-app>
</template>

<script>

import AppBar from "@/components/navigation/AppBar";
import Footer from "@/components/AppFooter.vue";
import {mapState} from "vuex";
import Overlay from "@/components/AppOverlay.vue";
import NavContent from "@/components/navigation/NavContent";
import Dialog from "@/components/SimpleDialog.vue";
import UnderMaintenance from "@/pages/UnderMaintenance";
import dayjs from "dayjs";
const WebFont = require('webfontloader');

export default {
  name: 'App',
  components: {UnderMaintenance, Dialog, AppBar, Footer, Overlay, NavContent},
  data() {
    return {
      idsRetryCount: 0,
      idsMaxRetry: 100,
      showSideNav: false,
      underMaintenance: process.env.VUE_APP_UNDER_MAINTENANCE === 'true'
    }
  },
  watch: {
    userEmail(val) {
      if (val) this.$matomo.setUserId(val)
      else this.$matomo.resetUserId()
    }
  },
  computed: {
    ...mapState({
      errorMessage(state) {
        return state.cart.error
      },
      isAppLoading(state) {
        return state.cart.loading || state.session.loading
      },
      sessionExpired(state) {
        return state.session.sessionExpired && this.$route.matched.some(record => record.meta.protected)
      }
    }),
  },
  methods: {
    toggleSideNav(event) {
      this.showSideNav = event !== undefined ? event : !this.showSideNav
    },
    async reconnectUser() {
      await this.$store.dispatch("session/logout")
      this.$keycloak.logoutFn()
    },
    async backToHome() {
      await this.$store.dispatch("session/logout")
      await this.$router.replace("/")
      this.$keycloak.logoutFn()
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async importKeyCloak() {
      require("@/plugins/keycloak")

      while (this.$keycloak && !this.$keycloak.ready && this.idsRetryCount <= this.idsMaxRetry) {
        console.log("ids attempt from app")
        this.idsRetryCount++
        await this.sleep(100)
      }
    },
    getExpiryDate() {
      const expiryPeriod = 90 * 24 * 60 * 60 * 1000;
      return dayjs().valueOf() + expiryPeriod

    },

    getParam(p) {
      const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    },
    trackGoogleCampaign() {
      // ?gclid=CjwKCAjw9LSSBhBsEiwAKtf0n-Tu8JzMObN_AetPA0dvbeAafciivhJc4q4ynShg5aNrKRG3_UM-lBoCD8AQAvD_BwE
      let gclidParam = this.getParam('gclid');
      let wbraidParam = this.getParam('wbraid');
      let gbraidParam = this.getParam('gbraid');

      let campaignTrackingId = null
      let campaignTrackingIdType = null
      if (gclidParam != null) {
        campaignTrackingId = gclidParam
        campaignTrackingIdType = "GCLID"

      } else if (wbraidParam != null) {
        campaignTrackingId = wbraidParam
        campaignTrackingIdType = "WBRAID"

      } else if (gbraidParam != null) {
        campaignTrackingId = gbraidParam
        campaignTrackingIdType = "GBRAID"
      }

      let gclsrcParam = this.getParam('gclsrc');
      let isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
      if (campaignTrackingId && isGclsrcValid) {

        this.$store.commit("session/setCampaignTrackingId", {
          campaignTrackingId,
          campaignTrackingIdType,
          campaignTrackingIdExpiryIn:  this.getExpiryDate()
        })
      }
    }
  },
  async mounted() {

    await this.importKeyCloak()
    console.log("keycloak loaded")

    // let campaign = this.$route.query.mtm_campaign
    // let campaignKey = this.$route.query.mtm_kwd
    //
    // this.$store.commit("session/setCampaign", campaign ? { name: campaign, key: campaignKey} : null)
    await this.$store.dispatch("shops/getShop")
    await this.$store.dispatch("session/getUserInfo")
    await this.$store.dispatch("vehicle/getVehicle")

    if (this.$route.fullPath.indexOf('/paiement/confirmation') === -1 &&
        this.$route.fullPath.indexOf('/commande/confirmation') === -1) {

      await this.$store.dispatch("cart/get")
    }

    WebFont.load({
      google: {
        families: ['Open Sans']
      }
    });

    //this.trackGoogleCampaign()

  },
  beforeDestroy() {
    this.$store.commit("session/setCampaign", null)
  }
}
</script>

<style lang="scss">

@import "src/styles/colors";

.advertise-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

}

.v-input.input-full-width > .v-input__control > .v-input__slot > .v-select__slot {
  margin: 0 0 0 1em;
}

.page {
  display: flex;
  height: 100%;
  padding-bottom: 2em;
}

.page.dense {
  padding: 0;
  margin: 0;
}

.page-content {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 1em;
}

.page-content.dense {
  padding: 0;
}

.page-content.no-padding {
  padding: 0;
}


.flex-centered {
  justify-content: center;
}

.block {
  background-color: #FFFFFF;
  border: 1px solid $border-color;

}

@media only screen and (max-width: 1280px) {
  .page-content {
    margin: 0 auto;
    padding: 1em 1em 1em;
  }
}


@media only screen and (max-width: 1000px) {
  .page-content {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 0.5em;
  }
}

@media only screen and (max-width: 700px) {
  .page-content {
    margin: 0 auto;
    padding: 0 0 1em;
  }


  .advertise-header {
    padding: 0.5em;

  }
}


</style>
