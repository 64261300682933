import axios from "axios";


export default {

    async get(cartId) {
        try {

            let url = cartId ? `/cart/${cartId}` : `/cart`
            let response = await axios.get(url)

            if (response.status === 200) return response?.data
            else return null

        } catch (e) {
            if (e.message.indexOf("401")) throw 401
            if (e.message.indexOf("404")) throw 404
            if (e.message.indexOf("403")) throw 403
            throw 500
        }
    },

    async refresh(cartId) {
        try {

            let response = await axios.get(`/cart/${cartId}/refresh`)
            if (response.status === 200) {
                return response.data
            } else if (response.status === 204) {
                return null
            }

        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            if (e.message.indexOf("403")) throw 403
            throw 500
        }
    },

    async delete(cartId) {
        try {

            let response = await axios.delete(`/cart/${cartId}`)
            return response?.data

        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            if (e.message.indexOf("403")) throw 403
            throw 500
        }
    },

    async addItem(reference, supplierReference, supplierId, carCodes, cartId) {

        try {
            let response = await axios.put("/cart", {
                cartId: cartId,
                carKey: carCodes,
                reference: reference,
                supplierReference: supplierReference,
                supplierId: supplierId
            })
            return response ? response.data : null

        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            throw 500
        }
    },

    async updateProductQuantity(cartId, payload) {
        try {

            let response = await axios.put(`/cart/${cartId}/product/${encodeURIComponent(payload.reference)}`, payload)

            return response ? response.data : null



        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            throw 500
        }
    },

    async removeProduct(payload, cartId) {
        try {
            let response = await axios.post(`/cart/${cartId}/product/delete`, payload)

            return response ? response.data : null

        } catch (e) {
            if (e.message.indexOf("404")) throw 404
            throw 500
        }
    },

    async checkout(cartId, userId) {
        try {

            let response = await axios.post(`/cart/${cartId}/checkout`, userId)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },

    async updateUserInfo(cartId, userInfo) {
        try {

            let response = await axios.put(`/cart/${cartId}/user`, userInfo)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },

    async updateDelivery(cartId, payload) {
        try {

            let response = await axios.put(`/cart/${cartId}/delivery`, payload)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },

    async getDeliveriesOptions(cartId) {
        try {

            let response = await axios.get(`/cart/${cartId}/deliveries`)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    }
}
