<template>
  <div class="search-results-container" v-if="Object.keys(results).length > 0">
    <div class="page-content in-column">
      <div class="search-results-header">
        <h3>Résultats de la recherche</h3>
        <v-btn icon class="close"  @click="$emit('on-close')"><v-icon large>mdi-close</v-icon></v-btn>
      </div>
      <div v-for="(result, i) in Object.keys(results)" v-bind:key="`search-result-${i}`">
        <div v-if="result === 'categories'">
          <h3>Catégories</h3>
          <div v-for="(category, j) in results['categories']" v-bind:key="`search-${result}-${j}`">
            <div class="category-results-header">
              <div><v-img v-if="category.icon" :src="require(`@/assets/icons/${category.icon}`)" height="20" width="20"></v-img></div>

              <h4>{{ category.name }}</h4>
            </div>
            <div class="sub-category">
              <div v-for="(subCat, k) in category.subCategories" v-bind:key="`sub-categories-${k}`">
                <span @click="openLink(computeCategoryUrl(subCat.name))">
                  {{subCat.name}}
                </span>
                <div v-if="subCat.subCategories" class="criterion">
                  <div v-for="(criterion, l) in subCat.subCategories" v-bind:key="`criterion-${l}`">
                    <span class="link" @click="openLink(computeCriterionUrl(subCat.name, criterion.name))">
                      {{criterion.name}}
                    </span>
                    <span v-if="j < subCat.subCategories.length - 1 "> / </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="result === 'reference'" class="results-item-container">

          <v-data-table class="results-data-table" :headers="searchResultsHeaders" :items="searchResults(results[result])"
                        disable-filtering disable-sort disable-pagination>
            v-slot:[`item.creationDate`]="{ item }">
            <template v-slot:[`item.productName`]="{ item }">
              <router-link :to="`/produits/${item.supplierId}/${encodeURIComponent(item.reference)}`"
                           v-on:click.native="openReference">{{ productName(item) }}
              </router-link>
            </template>
            <template v-slot:[`item.imgUrl`]="{ item }">
              <img class="image" :src="item.imgUrl" :alt="item.productName">
            </template>

            <template v-slot:[`item.supplierName`]="{ item }">
              <span class="supplier">{{ item.supplierName }}</span>
            </template>

            <template v-slot:[`item.price`]="{ item }">
              <div class="price-row">
                <div v-if="item.price">
                  <h3 class="price">{{ unitPriceTTCWithDeposit(item).toFixed(2) }} €</h3>
                  <div style="margin-bottom: 1em;" v-if="item.sellQuantity > 1"><em>soit {{unitPrice(item)}} l'unité</em></div>
                  <ProductDeposit :value="item" />
                  <AvailabilityStatus class="availability" :availability="item.availability" />
                </div>
                <AddToCart :item="item" @on-go-to-cart="$emit('on-open-ref')" disable-car-identification/>
              </div>
            </template>

          </v-data-table>

          <!--          <div class="result-item">-->
          <!--            <img style="height: 150px; width: auto" :src="results[result].imgUrl">-->
          <!--            <div>{{ results[result].productName }}</div>-->

          <!--          </div>-->

          <!--          <div class="result-item" v-for="(item, i) in results[result].correlations"-->
          <!--               v-bind:key="`${result.reference}-correlation-${i}`">-->
          <!--            <img style="height: 150px; width: auto" :src="item.imgUrl">-->
          <!--            {{ item.productName }}-->
          <!--          </div>-->


        </div>


        <!-- For future use to display categories and brands -->

      </div>
    </div>
  </div>
  <div v-else class="search-results-container" >
    <div class="page-content in-column">
      <div class="search-results-header">
        <h3>Aucun résultat</h3>
        <v-btn icon class="close"  @click="$emit('on-close')"><v-icon large>mdi-close</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import AddToCart from "@/components/AddToCart";
import ProductDeposit from "@/components/product/ProductDeposit";
import AvailabilityStatus from "@/components/product/AvailabilityStatus";
import {mapState} from "vuex";
import {isEmpty} from "lodash-es";

export default {
  name: 'SearchResults',
  components: {AvailabilityStatus, AddToCart, ProductDeposit},
  props: {
    results: Object,
    loading: Boolean
  },
  data() {
    return {
      searchResultsHeaders: [{
        text: "",
        value: 'imgUrl',
        align: 'center'
      },
        {
          text: 'Produit',
          value: 'productName',
        },
        {text: 'Marque', value: 'supplierName'},
        {text: 'Prix', value: 'price'}
      ]
    }
  },
  computed: {
    ...mapState({
      vehicle(state) {
        return state.vehicle.vehicle
      }
    })
  },
  methods: {

    computeCategoryUrl(subCat) {
      let url = `/catalogue`;
      if (this.vehicle && !isEmpty(this.vehicle)) {
        url += `/v${this.vehicle.url}`
      }
      return `${url}/c/${this.$store.getters['catalog/getCategoryNameAsURI'](subCat)}`
    },
    computeCriterionUrl(subCat, criterion) {
      let url = `/catalogue`;
      if (this.vehicle && !isEmpty(this.vehicle)) {
        url += `/v${this.vehicle.url}`
      }
      return `${url}/c/${this.$store.getters['catalog/getCategoryNameAsURI'](subCat)}/${this.$store.getters['catalog/getCategoryNameAsURI'](criterion)}`
    },

    productName(item) {
      let productName = item.productName
      if (item.sellQuantity > 1) {
        productName = productName.replace(/\(x1\)/g, "")
        productName += ` (lot de ${item.sellQuantity})`
      }

      return productName
    },
    unitPrice(item) {
      return item.price.toFixed(2)
    },
    unitPriceTTCWithDeposit(item) {

      return this.$unitPriceTTCWithDeposit(item)
    },
    searchResults(results) {
      let filteredResults = []
      let correlations = JSON.parse(JSON.stringify(results.correlations))
      delete results.correlations
      if (correlations && correlations !== {}) {
        if (results.supplierName !== 'CONSTRUCTEUR') correlations.unshift(results)
        filteredResults = correlations
      } else filteredResults = [results]

      return filteredResults.filter(p => {
        return process.env.VUE_APP_ENV !== 'production' ||
            (p.publicPrice > p.price && p.price > 0)
      })
    },

    openReference() {
      this.$emit("on-open-ref")
    },
    openLink(to) {
      this.$emit("on-open-ref")
      this.$router.push(to)
    }
  }
}
</script>

<style lang="scss">
.theme--light.v-data-table.ta-mere > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

.results-item-container {
  .v-data-table-header-mobile {
    display: none !important;
  }
}


</style>

<style lang="scss" scoped>

@import "src/styles/colors";
.close {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.4s;
}
.close:hover {
  opacity: 1;
}

.price-row {
  text-align: right;
  padding: 1em 0;
}

.price {
  font-weight: 700;
  font-size: 30px;
}

.availability {
  font-size: medium;
  margin-bottom: 0.5em;
}

.supplier {
  font-weight: 400;
  font-size: 24px;
}

.image {
  height: 150px;
  width: auto;
}

.sub-category {
  margin-left: 1.5em;

  .criterion {
    display: flex;
    flex-wrap: wrap;
  }
}

.sub-category div {
  padding: 0 0 0.5em 0.5em;
}


.search-results-container {
  position: fixed;
  top: 110px;
  right: 0;
  min-height: 100%;
  width: 100%;

  padding: 1em 4em 2em 4em;
  box-sizing: border-box;

  background: white;
  color: black;
  z-index: 100;

  border-bottom: 1px solid $border-color;

  .search-results-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    padding-top: 1em;
  }

  .results-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .category-results-header {
    display: flex;
    align-items: center;
  }

  .category-results-header h4:last-child {
    margin-left: 0.5em;
  }
}

.spin {
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 1000px) {

  .image {
    height: 70px;
    width: auto;
    padding-top: 0.8em;
  }
  .price-row {
    padding-bottom: 0.8em;
  }

  .search-results-container {
    position: absolute;
    top: 65px;
    padding: 0 1em 1em 1em;
    width: 100%;

    .results-item-container {

      tr.v-data-table__mobile-table-row {
        padding-top: 0.5em;
      }

    }
  }
}


</style>
