import axios from 'axios'

export default {

    async getOpeningHours(shopId) {
        let response =  await axios.get(`/shop/${shopId}/opening`)
        return response.data
    },

    async getShop(shopId) {
        let response =  await axios.get(`/shop/${shopId}`)
        return response.data
    }
}
