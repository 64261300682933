<template>
  <div class="deposit" v-if="deposit" @click="showDepositDialog = true">
    <span>{{`${totalPrice} à l'achat dont ${(deposit).toFixed(2)} € de consigne remboursée`}}</span>
    <Dialog class="deposit-dialog" title="Plus d'infos sur la consigne" :dialog="showDepositDialog" width="600">
      <template v-slot:content>
        <p>Les pièces consignées (alternateurs, démarreurs, cardans, ...) sont rénovées à neuf par les équipementiers. Elles sont autant fiable et ont les mêmes garanties que les pièces neuves. Elles sont également moins chères.</p>
        <p>Le prix de vente du produit inclut la consigne, celle ci vous sera remboursé après avoir rapporté la pièce au magasin Drive Pièces Auto le plus proche. Le remboursement se fait directement par carte bleu en magasin ou par avoir.</p>
        <p>Les demandes de retours doivent être effectués depuis votre espace personnel. La procédure est expliquée sur <router-link to="/sav/retours">la page d'aide concernée aux retours</router-link>.</p>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <dpa-btn text @click="showDepositDialog = false">OK</dpa-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/SimpleDialog.vue";
import DpaBtn from "@/components/Button";

export default {
  components: {DpaBtn, Dialog},
  props: {
    value: Object
  },
  data() {
    return {
      deposit: this.value ? this.value.priceHTConsigne * 1.2 : null,
      showDepositDialog: false
    }
  },
  computed: {

    totalPrice() {
      return this.$unitPriceTTCWithDeposit(this.value)
    }
  },
  watch: {
    value(val) {
      this.deposit = val ? val.priceHTConsigne * 1.2: null
    }
  }
}
</script>

<style lang="scss" scoped>

.deposit {
  color: #888;
 font-size: smaller;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.deposit-dialog {
  font-size: large !important;
}
</style>