var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Block',{staticClass:"product-wrapper",attrs:{"no-margin":"","no-shadow":_vm.flat,"no-border":_vm.flat}},[_c('div',{staticClass:"product"},[_c('div',{staticClass:"product-description-wrapper"},[_c('v-img',{attrs:{"height":_vm.imgHeight,"width":_vm.imgWidth,"src":_vm.product.imgUrl,"alt":_vm.productName}}),_c('div',{class:_vm.productDescriptionClass},[_c('div',[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.productName))]),_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.unitPriceTTCWithoutDeposit)+" €")]),(_vm.product.sellQuantity > 1)?_c('div',{staticStyle:{"margin-bottom":"1em"}},[_c('em',[_vm._v("soit "+_vm._s(_vm.unitPrice)+" € l'unité")])]):_vm._e(),_c('ProductDeposit',{attrs:{"value":_vm.product}}),_c('QuantitySelector',{attrs:{"quantity":_vm.quantity,"loading":_vm.updateQtyLoading,"product-ref":{
                              reference: _vm.product.reference,
                              supplierReference: _vm.product.supplierReference,
                              supplierId: _vm.product.supplierId,
                              supplier: !_vm.summarize ? _vm.product.supplier : null
                            },"align-right":_vm.alignRight},on:{"on-quantity-changed":_vm.updateQuantity}}),(_vm.error)?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('div',{class:_vm.priceBlockClass},[_c('div',[_vm._v(" "+_vm._s(_vm.itemTotalTTC)+" € ")]),(!_vm.noDelete)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch('cart/removeProduct', {
                   supplier: _vm.product.supplier,
                   supplierId: _vm.product.supplierId,
                   reference: _vm.product.reference,
                   supplierReference: _vm.product.supplierReference})}}},[_c('v-icon',{staticClass:"icon-link"},[_vm._v("mdi-delete")])],1):_vm._e()],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }