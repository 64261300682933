<template>
  <div class="side-nav">
    <div>
      <img  v-if="sideNav" src="/logo_white_mobile.png"  alt="Drive Pièces Auto" width="90%" height="auto" />
      <ul>
        <li v-if="sideNav || !$isMobile()" class="app-bar-item">
          <router-link to="/sav" @click.native="handleClick">
            <div class="app-bar-item-container">
              <v-icon class="menu-icon">mdi-help-circle-outline</v-icon>
              <span class="text">Aide et contact</span>
            </div>
          </router-link>
        </li>
        <li v-if="sideNav || !$isMobile()" class="app-bar-item">
          <router-link :to="$isMobile() ? '/account' : '/account/orders'" @click.native="handleClick">
            <div class="app-bar-item-container">
              <v-icon class="menu-icon">mdi-account</v-icon>
              <span class="text">Mon compte</span>
            </div>
          </router-link>
        </li>
        <li class="app-bar-item">
          <router-link to="/panier" @click.native="handleClick">
            <div class="app-bar-item-container">
              <v-badge class="badge-hack" :content="cartItemsNumber" :value="cartItemsNumber" overlap>
                <v-icon class="menu-icon">mdi-cart</v-icon>
              </v-badge>
              <span v-if="sideNav || !$isMobile()" class="text">Mon panier</span>
            </div>

          </router-link>
        </li>
        <li v-if="!hideSearch && (sideNav || !$isMobile())" class="app-bar-item">
          <router-link :to="catalogUrl" @click.native="handleClick">
            <div class="app-bar-item-container">
              <v-icon class="menu-icon">mdi-magnify</v-icon>
              <span class="text">Recherche une pièce</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="sideNav" style="color: #BEBEBF;">Drive de Pringy<br>39, avenue de fontainebleau 77310 Pringy</div>

  </div>

</template>

<script>
import {mapState} from "vuex";
import {isEmpty, reduce} from "lodash-es"

export default {

  props: {
    hideSearch: Boolean,
    sideNav: Boolean
  },
  data() {
    return {
      vehicleName: this.$store.getters["vehicle/getVehicleName"],
    }
  },
  computed: {
    ...mapState({
      cartItemsNumber(state) {
        return state.cart.items.length > 0 ?  reduce(state.cart.items, (a, b) => {
          return a + b.quantity
        }, 0) : 0
      },
      catalogUrl(state) {
        let url = "/catalogue"
        if (state.vehicle.vehicle && !isEmpty(state.vehicle.vehicle)) {
          url += `/v${state.vehicle.vehicle.url}`
        }
        return url
      },
      mainClass() {
        let clazz = "app-bar-item"
        if (this.sideNav) clazz += "for-side-nav"
        return clazz
      }
    }),
  },
  methods: {
    handleClick() {
      if (this.sideNav) this.$emit("on-menu-hit", false)
    }
  }
}
</script>

<style lang="scss" scoped>

@import "src/styles/colors";

.side-nav {
  padding: 1em;
}

ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;

  .app-bar-item.for-side-nav {
    a {
      padding: 0;

      .app-bar-item-container {
        padding: 0;
      }
    }
  }

  .app-bar-item {
    a {

      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      padding: 0 1rem;
      transition: color .35s ease;
      color: #BEBEBF;

      .app-bar-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

    }

    a:hover {
      color: $primary-color;
    }
  }
}

@media only screen and (max-width: 1280px) {

  ul {
    .app-bar-item {
      a {
        padding: 0;
        .app-bar-item-container {

        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .side-nav {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  ul {
    flex-direction: column-reverse;

    .app-bar-item {


      a {
        display: block;
      }


      a {
        height: 100%;
        padding: 0;
        .app-bar-item-container {

          display: flex;
          flex-direction: row;
          padding: 1em 0;

          .text {
            margin-left: 1em;
            font-size: 18px;
          }

          i, span {
            display: block;
            font-size: 22px;
          }
        }
      }
    }
  }
}
</style>
