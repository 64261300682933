<template>
  <div class="header-wrapper">
<!--      <div class="advertise-header" v-if="showAdvertisement">-->
<!--        <div class="advertise"><strong>Nouveau :</strong> Service de livraison en point relais disponible partout en France</div>-->
<!--        <v-btn icon @click="showAdvertisement = false"><v-icon>mdi-close</v-icon></v-btn>-->
<!--      </div>-->
    <div id="app-bar">
      <v-app-bar color="#27282C" class="app-nav" :height="!$isMobile() ? '88' : '47'" elevation="0">
        <div class="top-header">
          <v-app-bar-nav-icon v-if="$isMobile()" class="hamburger-menu" aria-label="MENU"
                              @click="toggleSideNav" dark>
          </v-app-bar-nav-icon>
        </div>
        <div class="left-menu">
          <router-link to="/" class="app-brand">
            <img  v-if="$isMobile()" src="/logo_white_mobile.png"  alt="Drive Pièces Auto" />
            <img v-else src="/logo_city_white.png" height="44" width="380" alt="Drive Pièces Auto" />
          </router-link>
        </div>
        <v-divider v-if="!$isMobile()"></v-divider>
        <SearchBar v-if="!$isMobile()"
                   placeholder="Catégorie ou référence"
                   type="text"
                   @on-open="isSearchOpen = true"
                   @on-close="isSearchOpen = false"/>
        <v-divider v-if="!$isMobile()"></v-divider>
        <div class="toolbar-wrapper">
          <NavContent hide-search @on-menu-hit="toggleSideNav"/>
        </div>

        <template v-slot:extension>
          <nav class="main-nav">
            <ul>
              <li @click="toggleSecondaryNav" :class="`${displaySecondaryNav ? 'main-nav-link selected' : 'main-nav-link'}`">
                Pièces détachées
                <v-icon v-if="!displaySecondaryNav">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon>
              </li>
              <li @click="$router.push('/sav')" style="text-align: end"><span class="store-opening">{{ shopOpening }}</span></li>
            </ul>

          </nav>
        </template>

      </v-app-bar>

      <CategorySelector v-if="displaySecondaryNav" @on-close="displaySecondaryNav = false"/>
      <Overlay :active="displaySecondaryNav || isSearchOpen" z-index="3"/>
    </div>
  </div>
</template>

<script>

import CategorySelector from "@/components/CategorySelector";
import {mapState} from "vuex";
import NavContent from "@/components/navigation/NavContent";
import Overlay from "@/components/AppOverlay.vue";
import SearchBar from "@/components/SearchBar";

export default {
  components: {NavContent, CategorySelector, Overlay, SearchBar},
  data() {
    return {
      displaySecondaryNav: false,
      isSearchOpen: false,
      shopOpening: null,
      showAdvertisement: true
    }
  },
  methods: {
    toggleSecondaryNav() {
      this.displaySecondaryNav = !this.displaySecondaryNav
      if (this.displaySecondaryNav) window.scrollTo(0, 0)
    },
    toggleSideNav() {
      this.$emit("on-menu-hit")
    }
  },
  computed: {
    ...mapState({
      cartItemsNumber(state) {
        return state.cart.items.length
      },
    }),
    vehicleName() {
      return this.$store.getters["vehicle/getVehicleName"]
    },

  },
  async mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.displaySecondaryNav = false
      next()
    })

    this.shopOpening = await this.$store.dispatch("session/getShopOpeningHours")
  }
}
</script>

<style lang="scss">

.header-wrapper {
  display: flex;
  flex-direction: column;
}
.badge-hack {
  .v-badge__badge {
    padding: 0 !important; //FIXME  Weird !!!
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#app-bar {
  .v-toolbar__content {
    padding: 0 calc((100% - 1280px)/2) 0;
  }
  .v-toolbar__extension {
    padding: 0 !important;
  }


}

@media only screen and (max-width: 1000px) {
  #app-bar {
    .v-toolbar__content, .v-toolbar__extension {
      justify-content: space-between;
    }


  }
}

</style>

<style lang="scss" scoped>

@import "src/styles/colors";

.popup-background {
  position: fixed;
  top: 300px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $background-color-gray-dark;
  opacity: 0;
  display: none;
}

.popup-background.visible {
  display: block;
  opacity: 0.8;
  overflow-y: hidden;

  z-index: 1;
}

.left-menu {
  display: flex;

  .app-brand {
    display: flex;
    font-size: xx-large;
    text-decoration: none;
    color: $primary-color;
    align-items: center;

    .location {
      //position: absolute;
      padding: 0.2em 0 0 0.5em;
      color: #FFFFFF;
      font-family: 'Nothing You Could Do', Arial, cursive;
    }

    img {
      height: 44px;
      width: auto;
      vertical-align: middle;
      font-family: 'Impact', arial, sans-serif;
    }
  }
}

#app-bar {

  display: flex;
  background-color: #27282C;
  color: $app-bar-text-color;
  z-index: 4;

  .app-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 4;


    .top-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: content-box;
      // border-bottom: 1px solid $border-color;

      .hamburger-menu {
        color: #BEBEBF;
      }
    }

    .toolbar-wrapper {
      display: flex;
      align-items: center;
    }

  }



  .main-nav {
    background-color: $background-color-gray-light;
    color: white;
    padding: 0 calc((100% - 1280px) / 2);
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;

    .store-opening {
      padding: 0.57rem 1rem;
      word-break: keep-all;
      align-self: center;
      font-weight: bold;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .main-nav-link {
        cursor: pointer;
        //padding: .75rem 1rem;
        height: 100%;
        transition: background-color 0.4s;
        display: flex;
        align-items: center;
        padding: 0 1em;
        i {
          color: white;
        }
      }

      .main-nav-link:hover {
        i {
          color: $primary-color;
        }

        color: $primary-color;
        background-color: $background-color-gray-lighter;
      }

      .main-nav-link.selected {
        i {
          color: $primary-color;
        }

        color: $primary-color;
        background-color: $background-color-gray-lighter;
      }

    }
  }


}

@media only screen and (max-width: 1280px) {
  .left-menu {
    .app-brand {
      padding-left: 0.5rem;
    }
  }
}

@media only screen and (max-width: 1000px) {

  #app-bar {
    padding: 0;

    .app-nav {
      .top-header {
        justify-content: space-between;
        padding: 0;
      }
    }

    .main-nav {
      font-size: 14px;
      height: 100%;
      align-items: center;
      padding: 0 1em 0 0;

      .store-opening {
        padding: 0;
        word-break: keep-all;
      }

      ul {

        .main-nav-link {
          padding: 0 1em;
          transition: none;
        }

        .main-nav-link:hover {
          i {
            color: white;
          }

          color: white;
          background-color: $background-color-gray-light;
        }

        .main-nav-link.selected {
          i {
            color: $primary-color;
          }

          color: $primary-color;
          background-color: $background-color-gray-lighter;
        }
      }
    }
  }

  .left-menu {
    .app-brand {
      font-size: 20px;
      padding-left: 0.3rem;

      .location {
        display: none;
      }

      img {
        height: 26px;
        width: auto;
        vertical-align: middle;
      }
    }
  }
}


</style>
