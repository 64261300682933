import axios from 'axios'

export default {

    async validateOrder(orderId, userId, cartId, paymentInfo) {

        try {

            let response = await axios.put(`/orders/${orderId}/${userId}/cart/${cartId}/payment`, paymentInfo)
            return response ? response.data : null

        } catch (e) {
            throw 500
        }
    },

    async loadOrders() {

        try {
            let response = await axios.get("/orders/user")
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async getInvoice(orderId) {
        try {

            return await axios.get(`/orders/${orderId}/invoice`)

        } catch (e) {
            throw 500
        }
    }

}
